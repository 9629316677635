import React from "react";
import "../components/SlideHover.css";
import LogoWrapperContact from "./LogoWrapperContact";
import EBTILogo from "../images/EBTILogo.jpg";
import KenyattaUni from "../images/KenyattaUni.png";
import HiveBioLab from "../images/HiveBioLab.jpg";
import NairobiUni from "../images/NairobiUni.jfif";
import MboaLab from "../images/LogoMboaLab.png";
import ISAAA from "../images/ISAAA.svg";

class SlideHoverContact extends React.Component {
    render() { 
        return (
        <>
        <div className="squares-container">
            <LogoWrapperContact 
                logo={HiveBioLab} 
                contactItem={"para_contact_HiveBioLab"}
                english={this.props.english} 
            />
            <LogoWrapperContact 
                logo={NairobiUni} 
                contactItem={"para_contact_NairobiUni"}
                english={this.props.english} 
            />
            <LogoWrapperContact  
                logo={MboaLab} 
                contactItem={"para_contact_MboaLab"}
                english={this.props.english} 
            />
        </div>
        <div className="squares-container">
            <LogoWrapperContact  
                logo={KenyattaUni} 
                contactItem={"para_contact_KenyattaUni"}
                english={this.props.english} 
            />
            <LogoWrapperContact  
                logo={ISAAA} 
                contactItem={"para_contact_ISAAA"}
                english={this.props.english} 
            />
            <LogoWrapperContact  
                logo={EBTILogo} 
                contactItem={"para_contact_EBTI"}
                english={this.props.english} 
            />
        </div>
        </>
        );
    }
}

export default SlideHoverContact;