import React from "react";
import "./Reference.css";
import english from "../text_files/english.json";
import parse from 'html-react-parser';

class Reference extends React.Component {
  render() {
    const references = this.props.references;
    let listReference = "";

    for (let i = 0; i < english[references].length; i++) {
      listReference += "<li> [" + (i+1).toString() + "]  " + english[references][i] + "</li>"
    }
    
    return (
      <>
      <ul className="ref-wrapper">
        {parse(listReference)}
      </ul>
      </>
    );
  }
}

export default Reference;