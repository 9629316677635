import React from "react";
import "../components/Toolbar.css";
import { Button, ButtonGroup, ButtonToolbar, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from "react-router-dom";
import english from "../text_files/english.json";
import french from "../text_files/french.json";

class Toolbar extends React.Component {
    render() {        
        return (
            <>
            <ButtonToolbar className="toolbar" >
                <ButtonGroup>
                    <Link className="link" to="/about">
                        <Button variant="light">
                            {this.props.english ? english.phrase_toolbar_about : french.phrase_toolbar_about}
                        </Button>
                    </Link>
                    <DropdownButton id="dropdown-getstarted" title={this.props.english ? english.phrase_toolbar_getstarted : french.phrase_toolbar_getstarted} className="button" variant="light">
                        <Link className="link" to="/general-presentation">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_generalpres : french.phrase_toolbar_generalpres}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/recombinant-enzymes">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_manuenzymes : french.phrase_toolbar_manuenzymes}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/CRISPR-based-biosensing">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_CRISPR : french.phrase_toolbar_CRISPR}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/CRISPR-based-biosensing" onClick={() => window.location.replace("/CRISPR-based-biosensing/#introduction-to-CRISPR")}>
                            <Dropdown.Item as="button" className="toolbar-subsection">
                                {this.props.english ? english.phrase_toolbar_introCRISPR : french.phrase_toolbar_introCRISPR}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/CRISPR-based-biosensing" onClick={() => window.location.replace("/CRISPR-based-biosensing/#biosensing-with-CRISPR")}>
                            <Dropdown.Item as="button" className="toolbar-subsection">
                                {this.props.english ? english.phrase_toolbar_biosensing : french.phrase_toolbar_biosensing}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/glossary">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_glossary : french.phrase_toolbar_glossary}
                            </Dropdown.Item>
                        </Link>
                    </DropdownButton>
                    <DropdownButton id="dropdown-protocols" title={this.props.english ? english.phrase_toolbar_protocols : french.phrase_toolbar_protocols} className="button" variant="light">
                        <Link className="link" to="/protocols-overview">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_overviewprotocols : french.phrase_toolbar_overviewprotocols}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/how-to-plasmids">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_howto : french.phrase_toolbar_howto}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/autoinduction-scale-up">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_autoinduction : french.phrase_toolbar_autoinduction}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/cell-free-expression">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_cellfree : french.phrase_toolbar_cellfree}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/crude-extract-preparation">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_crude : french.phrase_toolbar_crude}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/biosensor">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_biosensor : french.phrase_toolbar_biosensor}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/restriction-enzymes">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_restriction : french.phrase_toolbar_restriction}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/plasmid-design">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_plasmid : french.phrase_toolbar_plasmid}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/autolysis-and-autohydrolysis">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_hydro : french.phrase_toolbar_hydro}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/bioinformatics">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_bioinfo : french.phrase_toolbar_bioinfo}
                            </Dropdown.Item>
                        </Link>
                        <Link className="link" to="/how-to-set-up-a-lab">
                            <Dropdown.Item as="button">
                                {this.props.english ? english.phrase_toolbar_labsetup : french.phrase_toolbar_labsetup}
                            </Dropdown.Item>
                        </Link>
                    </DropdownButton>
                    <Link className="link" to="/contact">
                        <Button variant="light">
                            {this.props.english ? english.phrase_toolbar_contact : french.phrase_toolbar_contact}
                        </Button>
                    </Link>
                </ButtonGroup>
            </ButtonToolbar>
            </>
        ); 
    }
}

export default Toolbar;