import React from "react";
import "../body/GeneralOverview.css";
import "../body/Enzymes.css";
import "../body/Biosensor.css";
import Reference from "../Reference";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import TextImageContainer from "../primitive/TextImageContainer";
import WaveContainer from "../primitive/WaveContainer";
import layeredWave from "../../images/LayeredWaveDarkBlue.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import { Image } from 'react-bootstrap';
import TipProtocol from "../TipProtocol";
import RestrictionFigure1 from "../../images/RestrictionFigure1.svg";
import RestrictionFigure2 from "../../images/RestrictionFigure2.svg"; 
import RestrictionFigure3 from "../../images/RestrictionFigure3.svg"; 
import RestrictionFigure4 from "../../images/RestrictionFigure4.svg";   
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import ListEquipment from "../ListEquipment";
import parse from "html-react-parser";

class RestrictionE extends React.Component {
  render() { 
    return (
        <>
        <div className="overview-title">
            <TitleContainer text={this.props.english ? english.title_re : french.title_re} />
        </div>
        <div className="layered-wave">
            <Image src={layeredWave} />
        </div>
        <SubtitleContainer text={this.props.english ? parse(english.subtitle_re_intro) : parse(french.subtitle_re_intro)} />
        <TextContainer text={this.props.english ? parse(english.para_re_intro1) : parse(french.para_re_intro1)} />
        {this.props.fileDownload}
        <TextImageContainer text={this.props.english ? parse(english.para_re_intro2) : parse(french.para_re_intro2)} image={RestrictionFigure1} />
        <TextContainer text={this.props.english ? parse(english.para_re_intro3) : parse(french.para_re_intro3)} />
        <div className="bs-large-image">
            <ImageContainer image={RestrictionFigure2} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_re_intro4) : parse(french.para_re_intro4)} />
        <TextContainer text={this.props.english ? parse(english.para_re_intro5) : parse(french.para_re_intro5)} />
        <TextContainer text={this.props.english ? parse(english.para_re_intro6) : parse(french.para_re_intro6)} />
        <WaveContainer image={topWave} />
        <div className="pd-overview-section-blue">
            <SubtitleContainer text={this.props.english ? parse(english.subtitle_re_gel) : parse(french.subtitle_re_gel)} />
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? parse(english.subtitle_protocol_materials) : parse(french.subtitle_protocol_materials)} />
                    <ListEquipment english={this.props.english} equipment={"para_re_materials"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? parse(english.subtitle_protocol_equipment) : parse(french.subtitle_protocol_equipment)} />
                    <ListEquipment english={this.props.english} equipment={"para_re_equipment"} />
                </div>
            </div>
            <ol className="pd-list-body">
                <li>{this.props.english ? parse(english.para_re_gel1) : parse(french.para_re_gel1)}</li>
                <div className="bs-image bs-table">
                    <ImageContainer image={RestrictionFigure3} />
                </div>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_re_gel2) : parse(french.para_re_gel2)} />
                <li>{this.props.english ? parse(english.para_re_gel3) : parse(french.para_re_gel3)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_re_gel4) : parse(french.para_re_gel4)} />
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_re_gel5) : parse(french.para_re_gel5)} />
                <li>{this.props.english ? parse(english.para_re_gel6) : parse(french.para_re_gel6)}</li>
                <TextContainer text={this.props.english ? parse(english.para_re_gel7) : parse(french.para_re_gel7)} />
                <li>{this.props.english ? parse(english.para_re_gel8) : parse(french.para_re_gel8)}</li>
                <li>{this.props.english ? parse(english.para_re_gel9) : parse(french.para_re_gel9)}</li>
                <li>{this.props.english ? parse(english.para_re_gel10) : parse(french.para_re_gel10)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_re_gel11) : parse(french.para_re_gel11)} />
                <li>{this.props.english ? parse(english.para_re_gel12) : parse(french.para_re_gel12)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_re_gel13) : parse(french.para_re_gel13)} />
            </ol>
            <SubtitleContainer text={this.props.english ? parse(english.subtitle_re_digestion) : parse(french.subtitle_re_digestion)} />
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? parse(english.subtitle_protocol_materials) : parse(french.subtitle_protocol_materials)} />
                    <ListEquipment english={this.props.english} equipment={"para_digestion_materials"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? parse(english.subtitle_protocol_equipment) : parse(french.subtitle_protocol_equipment)} />
                    <ListEquipment english={this.props.english} equipment={"para_digestion_equipment"} />
                </div>
            </div>
            <ol className="pd-list-body">
                <li>{this.props.english ? parse(english.para_digestion_prot1) : parse(french.para_digestion_prot1)}</li>
                <ul>
                    <li>{this.props.english ? parse(english.para_digestion_prot2) : parse(french.para_digestion_prot2)}</li>
                    <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot3) : parse(french.para_digestion_prot3)} />
                    <li>{this.props.english ? parse(english.para_digestion_prot4) : parse(french.para_digestion_prot4)}</li>
                    <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot5) : parse(french.para_digestion_prot5)} />
                    <li>{this.props.english ? parse(english.para_digestion_prot6) : parse(french.para_digestion_prot6)}</li>
                    <li>{this.props.english ? parse(english.para_digestion_prot7) : parse(french.para_digestion_prot7)}</li>
                    <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot8) : parse(french.para_digestion_prot8)} />
                </ul>
                <li>{this.props.english ? parse(english.para_digestion_prot9) : parse(french.para_digestion_prot9)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot10) : parse(french.para_digestion_prot10)} />
                <li>{this.props.english ? parse(english.para_digestion_prot11) : parse(french.para_digestion_prot11)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot12) : parse(french.para_digestion_prot12)} />
                <li>{this.props.english ? parse(english.para_digestion_prot13) : parse(french.para_digestion_prot13)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot14) : parse(french.para_digestion_prot14)} />
                <li>{this.props.english ? parse(english.para_digestion_prot15) : parse(french.para_digestion_prot15)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot16) : parse(french.para_digestion_prot16)} />
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot17) : parse(french.para_digestion_prot17)} />
                <li>{this.props.english ? parse(english.para_digestion_prot18) : parse(french.para_digestion_prot18)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_digestion_prot19) : parse(french.para_digestion_prot19)} />
                <li>{this.props.english ? parse(english.para_digestion_prot20) : parse(french.para_digestion_prot20)}</li>
            </ol>
            <SubtitleContainer text={this.props.english ? parse(english.subtitle_re_oligos) : parse(french.subtitle_re_oligos)} />
            <TextContainer text={this.props.english ? parse(english.para_re_oligos1) : parse(french.para_re_oligos1)} />
            <div className="bs-large-image">
                <ImageContainer image={RestrictionFigure4} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_re_oligos2) : parse(french.para_re_oligos2)} />
            <SubtitleContainer text={this.props.english ? parse(english.subtitle_re_oligos_prot) : parse(french.subtitle_re_oligos_prot)} />
            <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_re_oligos4) : parse(french.para_re_oligos4)} />
            <TextContainer text={this.props.english ? parse(english.para_re_oligos3) : parse(french.para_re_oligos3)} />
            <ol className="pd-list-body">
                <li>{this.props.english ? parse(english.para_re_oligos5) : parse(french.para_re_oligos5)}</li>
                <li>{this.props.english ? parse(english.para_re_oligos6) : parse(french.para_re_oligos6)}</li>
                <li>{this.props.english ? parse(english.para_re_oligos7) : parse(french.para_re_oligos7)}</li>
            </ol>
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? english.subtitle_go_references : french.subtitle_go_references} />
        <Reference references={"para_re_references"} />
        </>
    );
  }
}

export default RestrictionE;