import React from "react";
import "./Contact.css";
import { Image } from 'react-bootstrap';
import ContactListItem from "../ContactListItem";
import SlideHoverContact from "../SlideHoverContact";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import introicon from "../../images/contact-intro.svg";
import contacticon from "../../images/contact-general-support.svg";
import partnericon from "../../images/deal.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from 'html-react-parser';

class ContactBody extends React.Component {
    render() {
        return (
            <>
            <div className="contact-title-section">
                <Image src={introicon} fluid />
                <SubtitleContainer text={this.props.english ? english.title_contact_intro : french.title_contact_intro} /> 
            </div>
            <TextContainer text={this.props.english ? parse(english.para_contact_intro) : parse(french.para_contact_intro)} />
            <b>
                <TextContainer text={this.props.english ? english.bold_contact_intro : french.bold_contact_intro} />
            </b>
            <div className="support-section">
                <div className="contact-title-section">
                    <Image src={contacticon} fluid />  
                    <SubtitleContainer text={this.props.english ? english.title_contact_support : french.title_contact_support} />
                </div>
                <ContactListItem contactItem={"para_contact_Cambridge"} english={this.props.english}/>
            </div>
            <div className="partner-labs-section">
                <div className="contact-title-section">
                    <Image src={partnericon} fluid />  
                    <SubtitleContainer text={this.props.english ? english.title_contact_partner : french.title_contact_partner} />
                </div>
                <SlideHoverContact english={this.props.english} />      
            </div>
            </>
        );
    }
}
    
export default ContactBody;