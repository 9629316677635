import React from "react";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import GraphicalAbstract from "../../images/CrudeExtractAbstract.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class CrudeExtractIntro extends React.Component {
    render() {
        return(
            <>
            <SubtitleContainer text={this.props.english ? english.subtitle_protocol_intro : french.subtitle_protocol_intro} />
            <ImageContainer image={GraphicalAbstract} />
            <TextContainer text={this.props.english ? parse(english.para_crude_intro) : parse(french.para_crude_intro)} />
            </>
        );
    }
}

export default CrudeExtractIntro;