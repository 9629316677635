import React from "react";
import "../body/GeneralOverview.css";
import "../body/Enzymes.css";
import "../body/Biosensor.css";
import Reference from "../Reference";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import WaveContainer from "../primitive/WaveContainer";
import layeredWave from "../../images/LayeredWaveDarkBlue.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import { Image, Button } from 'react-bootstrap';
import BiosensorFigure1 from "../../images/BiosensorFigure1.svg";
import BiosensorFigure2 from "../../images/BiosensorFigure2.svg"; 
import BiosensorFigure3 from "../../images/BiosensorFigure3.svg"; 
import BiosensorFigure4 from "../../images/BiosensorFigure4.svg"; 
import BiosensorFigure5 from "../../images/BiosensorFigure5.svg"; 
import BiosensorFigure6 from "../../images/BiosensorFigure6.svg"; 
import BiosensorFigure7 from "../../images/BiosensorFigure7.svg";
import BiosensorFigure8 from "../../images/BiosensorFigure8.svg"; 
import BiosensorFigure9 from "../../images/BiosensorFigure9.svg";  
import BiosensorFigure10 from "../../images/BiosensorFigure10.svg";
import BiosensorFigure11 from "../../images/BiosensorFigure11.svg"; 
import BiosensorFigure12 from "../../images/BiosensorFigure12.svg"; 
import BiosensorFigure13 from "../../images/BiosensorFigure13.svg";   
import Paratyphi from "../../documents/paratyphi-synthetic-template-design-ptyphi-lamp--grna-sequence.pdf";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import ListEquipment from "../ListEquipment";
import parse from "html-react-parser";

class Biosensor extends React.Component {
  render() { 
    return (
        <>
        <div className="overview-title">
            <TitleContainer text={this.props.english ? english.title_bs : french.title_bs} />
        </div>
        <div className="layered-wave">
            <Image src={layeredWave} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_bs_intro) : parse(french.para_bs_intro)} />
        {this.props.fileDownload}
        <TitleContainer text={this.props.english ? english.title_lamp : french.title_lamp} />
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro1) : parse(french.para_lamp_intro1)} />
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro2) : parse(french.para_lamp_intro2)} />
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro3) : parse(french.para_lamp_intro3)} />
        <div className="bs-large-image">
            <ImageContainer image={BiosensorFigure1} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro4) : parse(french.para_lamp_intro4)} />
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro5) : parse(french.para_lamp_intro5)} />
        <div className="bs-image">
            <ImageContainer image={BiosensorFigure2} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro6) : parse(french.para_lamp_intro6)} />
        <div className="bs-image">
            <ImageContainer image={BiosensorFigure3} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro7) : parse(french.para_lamp_intro7)} />
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro8) : parse(french.para_lamp_intro8)} />
        <TextContainer text={this.props.english ? parse(english.para_lamp_intro9) : parse(french.para_lamp_intro9)} />
        <SubtitleContainer text={this.props.english ? parse(english.subtitle_lamp_contam) : parse(french.subtitle_lamp_contam)} />
        <TextContainer text={this.props.english ? parse(english.para_lamp_contam1) : parse(french.para_lamp_contam1)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_lamp_contam_step1) : parse(french.para_lamp_contam_step1)}</li>
            <li>{this.props.english ? parse(english.para_lamp_contam_step2) : parse(french.para_lamp_contam_step2)}</li>
            <li>{this.props.english ? parse(english.para_lamp_contam_step3) : parse(french.para_lamp_contam_step3)}</li>
            <li>{this.props.english ? parse(english.para_lamp_contam_step4) : parse(french.para_lamp_contam_step4)}</li>
            <li>{this.props.english ? parse(english.para_lamp_contam_step5) : parse(french.para_lamp_contam_step5)}</li>
        </ol>
        <WaveContainer image={topWave} />
        <div className="pd-overview-section-blue">
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? parse(english.subtitle_protocol_materials) : parse(french.subtitle_protocol_materials)} />
                    <ListEquipment english={this.props.english} equipment={"para_lamp_materials"} />
                    <div className="bs-table">
                        <ImageContainer image={BiosensorFigure4} />
                    </div>
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? parse(english.subtitle_protocol_equipment) : parse(french.subtitle_protocol_equipment)} />
                    <ListEquipment english={this.props.english} equipment={"para_lamp_equipment"} />
                </div>
            </div>
            <div className="biosensor-button-container">
                <a href={Paratyphi} download="Paratyphi.pdf">
                    <Button variant="primary" className="biosensor-button">
                        {this.props.english ? english.phrase_lamp_pdf : french.phrase_lamp_pdf}
                    </Button>
                </a>
            </div>
            <SubtitleContainer text={this.props.english ? english.subtitle_lamp_protocol : french.subtitle_lamp_protocol} />
            <ol className="list-body-precipitation">
                <li>{this.props.english ? parse(english.para_lamp_step1) : parse(french.para_lamp_step1)}</li>
                <div className="bs-image bs-table">
                    <ImageContainer image={BiosensorFigure5} />
                </div>
                <TextContainer text={this.props.english ? parse(english.para_lamp_step1_text) : parse(french.para_lamp_step1_text)} />
                <li>{this.props.english ? parse(english.para_lamp_step2) : parse(french.para_lamp_step2)}</li>
                <div className="bs-image bs-table">
                    <ImageContainer image={BiosensorFigure6} />
                </div>
                <TextContainer text={this.props.english ? parse(english.para_lamp_step2_text) : parse(french.para_lamp_step2_text)} />
                <div className="bs-image bs-table">
                    <ImageContainer image={BiosensorFigure7} />
                </div>
                <ul>
                    <li>{this.props.english ? parse(english.para_lamp_list1) : parse(french.para_lamp_list1)}</li>
                    <li>{this.props.english ? parse(english.para_lamp_list2) : parse(french.para_lamp_list2)}</li>
                    <li>{this.props.english ? parse(english.para_lamp_list3) : parse(french.para_lamp_list3)}</li>
                    <li>{this.props.english ? parse(english.para_lamp_list4) : parse(french.para_lamp_list4)}</li>
                    <li>{this.props.english ? parse(english.para_lamp_list5) : parse(french.para_lamp_list5)}</li>
                </ul>
            </ol>
            <SubtitleContainer text={this.props.english ? parse(english.subtitle_lamp_results) : parse(french.subtitle_lamp_results)} />
            <TextContainer text={this.props.english ? parse(english.para_lamp_results1) : parse(french.para_lamp_results1)} />
            <div className="bs-table bs-large-image">
                <ImageContainer image={BiosensorFigure8} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_lamp_results2) : parse(french.para_lamp_results2)} />
            <div className="bs-table bs-large-image">
                <ImageContainer image={BiosensorFigure9} />
            </div>
        </div>
        <WaveContainer image={bottomWave} />
        <TitleContainer text={this.props.english ? english.title_crispr : french.title_crispr} />
        <SubtitleContainer text={this.props.english ? english.subtitle_crispr_intro : french.subtitle_crispr_intro} />
        <TextContainer text={this.props.english ? parse(english.para_crispr_intro1) : parse(french.para_crispr_intro1)} />
        <TextContainer text={this.props.english ? parse(english.para_cripsr_intro2) : parse(french.para_cripsr_intro2)} />
        <ImageContainer image={BiosensorFigure10} />
        <SubtitleContainer text={this.props.english ? english.subtitle_crispr_grna : french.subtitle_crispr_grna} />
        <TextContainer text={this.props.english ? parse(english.para_crispr_grna1) : parse(french.para_crispr_grna1)} />
        <TextContainer text={this.props.english ? parse(english.para_crispr_grna2) : parse(french.para_crispr_grna2)} />
        <div className="biosensor-button-container">
            <a href={Paratyphi} download="Paratyphi.pdf">
                <Button variant="primary" className="biosensor-button">
                    {this.props.english ? english.phrase_lamp_pdf : french.phrase_lamp_pdf}
                </Button>
            </a>
        </div>
        <SubtitleContainer text={this.props.english ? english.subtitle_crispr_cas12B : french.subtitle_crispr_cas12B} />
        <TextContainer text={this.props.english ? parse(english.para_crispr_cas12B) : parse(french.para_crispr_cas12B)} />
        <TextContainer text={this.props.english ? parse(english.para_crispr_cas12B1) : parse(french.para_crispr_cas12B1)} />
        <WaveContainer image={topWave} />
        <div className="pd-overview-section-blue">
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_materials : french.subtitle_protocol_materials} />
                    <ListEquipment english={this.props.english} equipment={"para_crispr_materiels"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_equipment : french.subtitle_protocol_equipment} />
                    <ListEquipment english={this.props.english} equipment={"para_crispr_equipment"} />
                </div>
            </div>
            <SubtitleContainer text={this.props.english ? english.subtitle_crispr_protocol : french.subtitle_crispr_protocol} />
            <TextContainer text={this.props.english ? parse(english.para_crispr_protocol1) : parse(french.para_crispr_protocol1)} />
            <div className="bs-image bs-table">
                <ImageContainer image={BiosensorFigure11} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_crispr_protocol2) : parse(french.para_crispr_protocol2)} />
            <SubtitleContainer text={this.props.english ? english.subtitle_crispr_detection : french.subtitle_crispr_detection} />
            <TextContainer text={this.props.english ? parse(english.para_crispr_detection1) : parse(french.para_crispr_detection1)} />
            <SubtitleContainer text={this.props.english ? english.subtitle_crispr_detection_ex : french.subtitle_crispr_detection_ex} />
            <div className="bs-image bs-table">
                <ImageContainer image={BiosensorFigure12} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_crispr_detection2) : parse(french.para_crispr_detection2)} />
            <TextContainer text={this.props.english ? parse(english.para_crispr_detection3) : parse(french.para_crispr_detection3)} />
            <SubtitleContainer text={this.props.english ? english.subtitle_crispr_detection_ex2 : french.subtitle_crispr_detection_ex2} />
            <div className="bs-image">
                <ImageContainer image={BiosensorFigure13} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_crispr_detection4) : parse(french.para_crispr_detection4)} />
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? english.subtitle_go_references : french.subtitle_go_references} />
        <Reference references={"para_bs_references"} />
        </>
    );
  }
}

export default Biosensor;