import React from "react";
import { Image } from 'react-bootstrap'; 
import "../components/LogoWrapper.css";

class LogoWrapper extends React.Component {
    render() { 
        return (
        <>
        <div className="img-wrapper">
            <Image className="image-partner" src={this.props.logo} />
            <div className="text-wrapper-partner">
                <div className="subtitle-partner">
                    {this.props.title}
                </div> 
                <div className="text-partner">
                    {this.props.description}
                </div>
            </div>
        </div>
        </>
        );
    }
}

export default LogoWrapper;