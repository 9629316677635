import React from "react";
import "./WaveContainer.css";
import { Image } from 'react-bootstrap'; 

class WaveContainer extends React.Component {
  render() {
    return (
      <div className="wave-container">
        <Image src={this.props.image} />
      </div>
    );
  }
}

export default WaveContainer;