import React from "react";
import "../body/GeneralOverview.css";
import "../body/Enzymes.css";
import "../body/Biosensor.css";
import Reference from "../Reference";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import WaveContainer from "../primitive/WaveContainer";
import layeredWave from "../../images/LayeredWaveDarkBlue.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import { Image } from 'react-bootstrap'; 
import BioinfoFigure1 from "../../images/BioinfoFigure1.svg";
import BioinfoFigure2 from "../../images/BioinfoFigure2.svg";
import BioinfoFigure3 from "../../images/BioinfoFigure3.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class Bioinformatics extends React.Component {
  render() { 
    return (
        <>
        <div className="overview-title">
            <TitleContainer text={this.props.english ? english.title_info : french.title_info} />
        </div>
        <div className="layered-wave">
            <Image src={layeredWave} />
        </div>
        <SubtitleContainer text={this.props.english ? english.subtitle_info_what : french.subtitle_info_what} />
        <TextContainer text={this.props.english ? english.para_info_what1 : french.para_info_what1} />
        {this.props.fileDownload}
        <WaveContainer image={topWave} />
        <div className="overview-section-blue">
            <SubtitleContainer text={this.props.english ? english.subtitle_info_case : french.subtitle_info_case} />
            <TextContainer text={this.props.english ? english.para_info_case1 : french.para_info_case1} />
            <TextContainer text={this.props.english ? english.para_info_case2 : french.para_info_case2} />
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? english.subtitle_info_blast : french.subtitle_info_blast} />
        <ImageContainer image={BioinfoFigure1}/>
        <TextContainer text={this.props.english ? english.para_info_figure1 : french.para_info_figure1} />
        <TextContainer text={this.props.english ? parse(english.para_info_blast1) : parse(french.para_info_blast1)} />
        <TextContainer text={this.props.english ? english.para_info_blast2 : french.para_info_blast2} />
        <ImageContainer image={BioinfoFigure2} />
        <TextContainer text={this.props.english ? english.para_info_figure2 : french.para_info_figure2} />
        <div className="bs-large-image">
            <ImageContainer image={BioinfoFigure3} />
        </div>
        <TextContainer text={this.props.english ? english.para_info_figure3 : french.para_info_figure3} />
        <SubtitleContainer text={this.props.english ? english.subtitle_info_other : french.subtitle_info_other} />
        <TextContainer text={this.props.english ? english.para_info_other1 : french.para_info_other1} />
        <TextContainer text={this.props.english ? english.para_info_other2 : french.para_info_other2} />
        <WaveContainer image={topWave} />
        <div className="overview-section-blue">
            <SubtitleContainer text={this.props.english ? english.subtitle_info_eval : french.subtitle_info_eval} />
            <TextContainer text={this.props.english ? english.para_info_eval1 : french.para_info_eval1} />
            <TextContainer text={this.props.english ? english.para_info_eval2 : french.para_info_eval2} />
            <TextContainer text={this.props.english ? english.para_info_eval3 : french.para_info_eval3} />
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? english.subtitle_go_references : french.subtitle_go_references} />
        <Reference references={"para_info_references"} />
        </>
    );
  }
}

export default Bioinformatics;