import React from "react";
import "../primitive/TextImageContainer.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import ImageContainer from "../primitive/ImageContainer";
import TextContainer from "../primitive/TextContainer";

class TextImageContainer extends React.Component {
    render() {
        if (this.props.subtitle !== undefined) {
            if (this.props.textLeft) {
                return (
                    <div className="block-wrapper">
                        <div className="text-wrapper">
                            <SubtitleContainer text={this.props.subtitle} />
                            <TextContainer text={this.props.text} />
                        </div>
                        <div className="image-wrapper">
                            <ImageContainer image={this.props.image} />
                        </div>
                    </div>
                )
            } 
            else {
                return (
                    <div className="block-wrapper">
                        <div className="image-wrapper">
                            <ImageContainer image={this.props.image} />
                        </div>
                        <div className="text-wrapper">
                            <SubtitleContainer text={this.props.subtitle} />
                            <TextContainer text={this.props.text} />
                        </div>
                    </div>
                )
            }
        }
        else {
            if (this.props.textLeft) {
                return (
                    <div className="block-wrapper">
                        <div className="text-wrapper">
                            <TextContainer text={this.props.text} />
                        </div>
                        <div className="image-wrapper">
                            <ImageContainer image={this.props.image} />
                        </div>
                    </div>
                )
            } 
            else {
                return (
                    <div className="block-wrapper">
                        <div className="image-wrapper">
                            <ImageContainer image={this.props.image} />
                        </div>
                        <div className="text-wrapper">
                            <TextContainer text={this.props.text} />
                        </div>
                    </div>
                )
            }
        } 
    }
}
    
export default TextImageContainer;