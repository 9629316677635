import React from "react";
import "../protocols/CellFreeBody.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import TextImageContainer from "../primitive/TextImageContainer";
import ImageContainer from "../primitive/ImageContainer";
import ListEquipment from "../ListEquipment";
import pDawnPlasmid from "../../images/pDawn-plasmid.svg";
import AutolysisFigure3 from "../../images/AutolysisFigure3.svg";
import AutolysisFigure4 from "../../images/AutolysisFigure4.svg";
import AutolysisFigure5 from "../../images/AutolysisFigure5.svg";
import Sequence2 from "../../documents/pDawn-endonuclease-sequence.pdf";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class AutolysisBody extends React.Component {
    render() {
        return(
            <>
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_materials : french.subtitle_protocol_materials} />
                    <ListEquipment english={this.props.english} equipment={"para_hydro_materials"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_equipment : french.subtitle_protocol_equipment} />
                    <ListEquipment english={this.props.english} equipment={"para_hydro_equipment"} />
                </div>
            </div>
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_protocolauto : french.subtitle_hydro_protocolauto} />
            <ol className="list-body-precipitation">
                <li>{this.props.english ? parse(english.para_hydro_auto1) : parse(french.para_hydro_auto1)}</li>
                <li>{this.props.english ? parse(english.para_hydro_auto2) : parse(french.para_hydro_auto2)}</li>
                <li>{this.props.english ? parse(english.para_hydro_auto3) : parse(french.para_hydro_auto3)}</li>
                <li>{this.props.english ? parse(english.para_hydro_auto4) : parse(french.para_hydro_auto4)}</li>
            </ol>
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_example : french.subtitle_hydro_example} />
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_pd : french.subtitle_hydro_pd} />
            <TextContainer text={this.props.english ?
                ["The plasmid we sent to you is a recombinant plasmid that can achieve autohydrolysis, designed with a micrococcal nuclease gene (nucA) insertion (sequence shows ", <a href={Sequence2} download='Sequence.pdf'>here</a>, " and ", <a href='https://benchling.com/s/seq-U7S5G1720pjCXtdBMqZm/edit' target='_blank' rel='noreferrer'>here</a>, "). We aimed to control the autohydrolysis by a blue light inducible promoter, pDawn [8], therefore the micrococcal nuclease was inserted downstream of the pDawn promoter (Figure 2)."]
                : ["Le plasmide que nous vous avons envoyé est un plasmide recombinant qui permet de réaliser l'auto-hydrolyse. Il a été développé avec l'insertion d'un gène de nucléase micrococcal (nucA) (voir la séquence ", <a href={Sequence2} download='Sequence.pdf'>ici</a>, " et ", <a href='https://benchling.com/s/seq-U7S5G1720pjCXtdBMqZm/edit' target='_blank' rel='noreferrer'>ici</a>, "). Notre but est de contrôler l’auto-hydrolyse avec un promoteur inductible par lumière bleue, pDawn [8]. Par conséquent, le nucléase micrococcal a été inséré en aval du promoteur pDawn (Figure 2)."]
                }
            />
            <div className="bs-large-image">
                <ImageContainer image={pDawnPlasmid} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_hydro_figure2) : parse(french.para_hydro_figure2)} />
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_results : french.subtitle_hydro_results} />
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_bluelight : french.subtitle_hydro_bluelight} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_bluelight) : parse(french.para_hydro_bluelight)} />
            <TextImageContainer text={this.props.english ? parse(english.para_hydro_figure3) : parse(french.para_hydro_figure3)} image={AutolysisFigure3} />
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_auto : french.subtitle_hydro_auto} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_auto) : parse(french.para_hydro_auto)} />
            <TextImageContainer text={this.props.english ? parse(english.para_hydro_figure4) : parse(french.para_hydro_figure4)} image={AutolysisFigure4} />
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_nuclease : french.subtitle_hydro_nuclease} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_nuclease) : parse(french.para_hydro_nuclease)} />
            <TextImageContainer text={this.props.english ? parse(english.para_hydro_figure5) : parse(french.para_hydro_figure5)} image={AutolysisFigure5} />
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_protocollysis : french.subtitle_hydro_protocollysis} />
            <ol className="list-body-precipitation">
                <li>{this.props.english ? parse(english.para_hydro_protocollysis1) : parse(french.para_hydro_protocollysis1)}</li>
                <li>{this.props.english ? parse(english.para_hydro_protocollysis2) : parse(french.para_hydro_protocollysis2)}</li>
                <li>{this.props.english ? parse(english.para_hydro_protocollysis3) : parse(french.para_hydro_protocollysis3)}</li>
                <li>{this.props.english ? parse(english.para_hydro_protocollysis4) : parse(french.para_hydro_protocollysis4)}</li>
            </ol>
            </>
        );
    }
}

export default AutolysisBody;