// General
import React from "react"
import { Route, Switch } from "react-router-dom"
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Pages
import PageContainer from "./components/primitive/PageContainer"
import Home from "./components/body/Home"
import About from "./components/body/About"
import Protocol from "./components/body/Protocol"
import ProtocolsOverview from "./components/body/ProtocolsOverview"
import Glossary from "./components/body/Glossary"
import Contact from "./components/body/Contact"
import GetStarted from "./components/body/GetStarted"
import Enzymes from "./components/body/Enzymes"
import CRISPR from "./components/body/CRISPR"
import PlasmidDesign from "./components/body/PlasmidDesign"
import Bioinformatics from "./components/body/Bioinformatics";
import Biosensor from "./components/body/Biosensor";
import RestrictionE from "./components/body/RestrictionE";
import StartLab from "./components/body/StartLab";

// Protocols
import AutoinductionBody from "./components/protocols/AutoinductionBody";
import AutoinductionIntro from "./components/protocols/AutoinductionIntro";
import HowtoIntro from "./components/protocols/HowtoIntro";
import HowtoBody from "./components/protocols/HowtoBody";
import CellFreeIntro from "./components/protocols/CellFreeIntro";
import CellFreeBody from "./components/protocols/CellFreeBody";
import CrudeExtractIntro from "./components/protocols/CrudeExtractIntro";
import CrudeExtractBody from "./components/protocols/CrudeExtractBody";
import CrudeExtractProof from "./components/protocols/CrudeExtractProof";
import AutolysisIntro from "./components/protocols/AutolysisIntro";
import AutolysisBody from "./components/protocols/AutolysisBody";
import DownloadProtocolButton from "./components/protocols/DownloadProtocolButton";

// PDFs
import ProtocolAutoinductionScaleUp from "./documents/Autoinduction.pdf";
import ProtocolAutoinductionScaleUp_FR from "./documents/Autoinduction_FR.pdf";
import HowtoPlasmids from "./documents/HowtoPlasmids.pdf";
import HowtoPlasmids_FR from "./documents/HowtoPlasmids_FR.pdf";
import CellFreeExpression from "./documents/CellFree.pdf";
import CellFreeExpression_FR from "./documents/CellFree_FR.pdf";
import CrudeExtract from "./documents/CrudeExtract.pdf";
import CrudeExtract_FR from "./documents/CrudeExtract_FR.pdf";
import Biosensor_EN from "./documents/Biosensor.pdf";
import Biosensor_FR from "./documents/Biosensor_FR.pdf";
import Restriction_EN from "./documents/Restriction.pdf";
import Restriction_FR from "./documents/Restriction_FR.pdf";
import PlasmidDesign_EN from "./documents/PlasmidDesign.pdf";
import PlasmidDesign_FR from "./documents/PlasmidDesign_FR.pdf";
import Autolysis_EN from "./documents/Autolysis.pdf";
import Autolysis_FR from "./documents/Autolysis_FR.pdf";
import Bioinformatics_EN from "./documents/Bioinformatics.pdf";
import Bioinformatics_FR from "./documents/Bioinformatics_FR.pdf";
import SetupLab_EN from "./documents/SetupLab.pdf";
import SetupLab_FR from "./documents/SetupLab_FR.pdf";

const Routes = (props) => {
  const NotFound = () => {
    return "Page not found."
  }

  const useLocationBlocker = () => {
    const history = useHistory();
    useEffect(
      () =>
        history.block(
          (location, action) =>
            action !== "PUSH" ||
            getLocationId(location) !== getLocationId(history.location)
        ),
      [] // eslint-disable-line react-hooks/exhaustive-deps
    );
  }
  
  const getLocationId = ({ pathname, search, hash }) => {
    return pathname + (search ? "?" + search : "") + (hash ? "#" + hash : "");
  }

  useLocationBlocker()
  return (
    <Switch>
      <Route exact path="/" render={() => (<PageContainer page={<Home english={props.english} />} />)} />
      <Route path="/educational-toolkit" render={() => (<PageContainer page={<Home english={props.english} />} />)} />
      <Route path="/general-presentation" render={() => (<PageContainer page={<GetStarted english={props.english} />} />)} />
      <Route path="/protocols-overview" render={() => (<PageContainer page={<ProtocolsOverview english={props.english} />} />)} />
      <Route path="/about" render={() => (<PageContainer page={<About english={props.english} />} />)} />
      <Route path="/autoinduction-scale-up" render={() => (<PageContainer page={
        <Protocol
          english={props.english}
          title={"title_autoinduction"}
          introduction={<AutoinductionIntro english={props.english} />}
          fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={ProtocolAutoinductionScaleUp} FrenchFile={ProtocolAutoinductionScaleUp_FR} />}
          body={<AutoinductionBody english={props.english} />}
          video={"G3zOPvTvGJ4"}
          references={"para_autoinduction_references"}
        />
      } />)} />
      <Route path="/how-to-plasmids" render={() => (<PageContainer page={
        <Protocol
          english={props.english}
          title={"title_howto"}
          introduction={<HowtoIntro english={props.english} />}
          fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={HowtoPlasmids} FrenchFile={HowtoPlasmids_FR} />}
          body={<HowtoBody english={props.english} />}
          references={"para_howto_references"}
        />
      } />)} />
      <Route path="/cell-free-expression" render={() => (<PageContainer page={
        <Protocol
          english={props.english}
          title={"title_cellfree"}
          introduction={<CellFreeIntro english={props.english} />}
          fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={CellFreeExpression} FrenchFile={CellFreeExpression_FR} />}
          body={<CellFreeBody english={props.english} />}
          references={"para_cellfree_references"}
        />
      } />)} />
      <Route path="/crude-extract-preparation" render={() => (<PageContainer page={
        <Protocol
          english={props.english}
          title={"title_crude"}
          introduction={<CrudeExtractIntro english={props.english} />}
          fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={CrudeExtract} FrenchFile={CrudeExtract_FR} />}
          body={<CrudeExtractBody english={props.english} />}
          proof={<CrudeExtractProof english={props.english} />}
          references={"para_crude_references"}
        />
      } />)} />
      <Route path="/autolysis-and-autohydrolysis" render={() => (<PageContainer page={
        <Protocol
          english={props.english}
          title={"title_hydro"}
          introduction={<AutolysisIntro english={props.english} />}
          fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={Autolysis_EN} FrenchFile={ Autolysis_FR} />}
          body={<AutolysisBody english={props.english} />}
          references={"para_hydro_references"}
        />
      } />)} />
      <Route path="/plasmid-design" render={() => (<PageContainer page={<PlasmidDesign english={props.english} fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={PlasmidDesign_EN} FrenchFile={PlasmidDesign_FR} />} />} />)} />
      <Route path="/biosensor" render={() => (<PageContainer page={<Biosensor english={props.english} fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={Biosensor_EN} FrenchFile={Biosensor_FR} />} />} />)} />
      <Route path="/bioinformatics" render={() => (<PageContainer page={<Bioinformatics english={props.english} fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={Bioinformatics_EN} FrenchFile={Bioinformatics_FR} />} />} />)} />
      <Route path="/restriction-enzymes" render={() => (<PageContainer page={<RestrictionE english={props.english} fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={Restriction_EN} FrenchFile={Restriction_FR} />}/>} />)} />
      <Route path="/how-to-set-up-a-lab" render={() => (<PageContainer page={<StartLab english={props.english} fileDownload={<DownloadProtocolButton english={props.english} EnglishFile={SetupLab_EN} FrenchFile={SetupLab_FR} />} />} />)} />
      <Route path="/recombinant-enzymes" render={() => (<PageContainer page={<Enzymes english={props.english} />} />)} />
      <Route path="/CRISPR-based-biosensing" render={() => (<PageContainer page={<CRISPR english={props.english} />} />)} />
      <Route path="/glossary" render={() => (<PageContainer page={<Glossary english={props.english} />} />)} />
      <Route path="/contact" render={() => (<PageContainer page={<Contact english={props.english} />} />)} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes