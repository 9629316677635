import React from "react";
import "../protocols/AutolysisIntro.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import AutolysisFigure1 from "../../images/AutolysisFigure1.svg";
import Sequence1 from "../../documents/phage--R-endolysin-sequence.pdf";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";


class AutolysisIntro extends React.Component {
    render() {
        return(
            <>
            <TextContainer text={this.props.english ? parse(english.para_hydro_intro1) : parse(french.para_hydro_intro1)} />
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_autolysis : french.subtitle_hydro_autolysis} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_autolysis1) : parse(french.para_hydro_autolysis1)} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_autolysis2) : parse(french.para_hydro_autolysis2)} />
            <TextContainer text={this.props.english ? [<b>Endolysin</b>, " is an enzyme produced by bacteriophages to break the bacterial cell wall. It functions by targeting and breaking one of the bonds in peptidoglycan, the main component of the bacterial cell wall. This results in cell rupture. For example, the ", <b>phage λ R endolysin</b>, " gene (sequence shown ", <a href={Sequence1} download='Sequence.pdf'>here</a>, ") has been inserted into a plasmid to achieve autolysis [4]."] : ["L'", <b>endolysine</b>, " est une enzyme produite par des bactériophages pour rompre la paroi cellulaire des bactéries. Cela fonctionne en ciblant et cassant l’une des liaisons du peptidoglycane, le composant principal de la paroi cellulaire bactérienne. Ceci résulte en la rupture de la cellule. Par exemple, le gène ", <b>phage λ R endolysin</b>, " (voir la séquence ", <a href={Sequence1} download='Sequence.pdf'>ici</a>, ") a été inséré dans un plasmide pour l’autolyse [4]."]} />
            <SubtitleContainer text={this.props.english ? english.subtitle_hydro_autohydrolysis : french.subtitle_hydro_autohydrolysis} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_autohydrolysis1) : parse(french.para_hydro_autohydrolysis1)} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_autohydrolysis2) : parse(french.para_hydro_autohydrolysis2)} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_autohydrolysis3) : parse(french.para_hydro_autohydrolysis3)} />
            <ImageContainer image={AutolysisFigure1} />
            <TextContainer text={this.props.english ? parse(english.para_hydro_figure1) : parse(french.para_hydro_figure1)} />
            </>
        );
    }
}

export default AutolysisIntro;