import React from "react";
import "../body/GeneralOverview.css";
import "../body/CRISPR.css";
import Reference from "../Reference";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import WaveContainer from "../primitive/WaveContainer";
import layeredWave from "../../images/LayeredWaveDarkBlue.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import CRISPRCas9 from "../../images/CRISPRCas9GeneEditing.svg";
import CRISPRCollateral from "../../images/CRISPRCollateralDetection.svg";
import LAMP from "../../images/LAMP.svg";
import PCR from "../../images/PCR.svg";
import { Image } from 'react-bootstrap'; 
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class CRISPR extends React.Component {
  render() { 
    return (
      <>
      <div className="overview-title">
        <TitleContainer text={this.props.english ? english.title_go_crispr : french.title_go_crispr} />
      </div>
      <div className="layered-wave">
        <Image src={layeredWave} />
      </div>
      <div id={"introduction-to-CRISPR"}>
        <TitleContainer text={this.props.english ? english.title_cripsr_section1 : french.title_cripsr_section1} />
      </div>
      <SubtitleContainer text={this.props.english ? english.subtitle_crispr_what : french.subtitle_crispr_what} />
      <TextContainer text={this.props.english ? parse(english.para_crispr_what1) : parse(french.para_crispr_what1)} />
      <TextContainer text={this.props.english ? parse(english.para_crispr_what2) : parse(french.para_crispr_what2)} />
      <WaveContainer image={topWave} />
      <div className="overview-section-blue">
        <SubtitleContainer text={this.props.english ? english.subtitle_crispr_how : french.subtitle_crispr_how} />
        <TextContainer text={this.props.english ? parse(english.para_crispr_how) : parse(french.para_crispr_how)} />
        <ImageContainer image={CRISPRCas9} />
      </div>
      <WaveContainer image={bottomWave} />
      <div id={"biosensing-with-CRISPR"}>
        <TitleContainer text={this.props.english ? english.title_cripsr_section2 : french.title_cripsr_section2} />
      </div>
      <SubtitleContainer text={this.props.english ? english.subtitle_cripsr_biosensors : french.subtitle_cripsr_biosensors} />
      <TextContainer text={this.props.english ? parse(english.para_cripsr_biosensors1) : parse(french.para_cripsr_biosensors1)} />
      <TextContainer text={this.props.english ? english.para_cripsr_biosensors2 : french.para_cripsr_biosensors2} />
      <TextContainer text={this.props.english ? parse(english.para_cripsr_biosensors3) : parse(french.para_cripsr_biosensors3)} />
      <div className="smaller-size-image-container" >
        <ImageContainer image={CRISPRCollateral} />
      </div>
      <WaveContainer image={topWave} />
      <div className="overview-section-blue">
        <SubtitleContainer text={this.props.english ? english.subtitle_cripsr_biosensorshow : french.subtitle_cripsr_biosensorshow} />
        <TextContainer text={this.props.english ? parse(english.para_cripsr_biosensorshow1) : parse(french.para_cripsr_biosensorshow1)} />
        <div className="smaller-size-image-container" >    
          <ImageContainer image={PCR} />
        </div>
        <TextContainer text={parse(this.props.english ? english.para_cripsr_biosensorshow2 : french.para_cripsr_biosensorshow2)} />
        <ImageContainer image={LAMP} />
        <TextContainer text={parse(this.props.english ? english.para_cripsr_biosensorshow3 : french.para_cripsr_biosensorshow3)} />
      </div>
      <WaveContainer image={bottomWave} />
      <SubtitleContainer text={this.props.english ? english.subtitle_go_references : french.subtitle_go_references} />
      <Reference references={"para_crispr_references"} />
      </>
    );
  }
}

export default CRISPR;