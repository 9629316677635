import React from "react";
import "../components/TipProtocol.css";
import TextContainer from "../components/primitive/TextContainer";
import SubtitleContainer from "../components/primitive/SubtitleContainer";
import english from "../text_files/english.json";
import french from "../text_files/french.json";

class TipProtocol extends React.Component {
  render(){  
    return (
        <>
        <div className="tip-container">
            <SubtitleContainer text={this.props.english ? english.subtitle_protocol_tip : french.subtitle_protocol_tip} />
            <TextContainer text={this.props.text} />
        </div>
        </>
    );
  }
}

export default TipProtocol;