import React from "react";
import "./Home.css";
import TitleContainer from "../primitive/TitleContainer";
import TextContainer from "../primitive/TextContainer";
import CRISPRHomePage from "../../images/CRISPRHomePageDarkBlue2.jpeg";
import handDNAImage from "../../images/HandDNARightSide.svg";
import Button from "react-bootstrap/Button";
import Link from "react-router-dom/Link";
import { Image } from 'react-bootstrap';
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";

class Home extends React.Component {
  render() {
    let scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      });
    };

    return (
      <>
      <div className="intro-homepage-wrapper">
        <Image src={CRISPRHomePage} />
        <div className="text-intro-homepage-wrapper">
          <div className="slogan-homepage">
            <TitleContainer text={this.props.english ? english.phrase_home_slogan : french.phrase_home_slogan} />
          </div>
          <div className="toolkit-button-container">
            <Link className="link" to="/general-presentation" >
              <Button variant="primary" className="toolkit-button" onClick={scrollToTop()}>
                {this.props.english ? english.phrase_home_start : french.phrase_home_start}
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="description-homepage">
        <TextContainer text={this.props.english ? english.para_home_introduction : french.para_home_introduction} />
        <Image src={handDNAImage} />
      </div>
      </>
    );
  }
}
  
export default Home;