import React from "react";
import "../components/ListEquipment.css";
import english from "../text_files/english.json";
import french from "../text_files/french.json";
import parse from 'html-react-parser';

class ListEquipment extends React.Component {
  render() {
    const equipmentName = this.props.equipment;
    let listEquipment = "";
    
    if (this.props.english) {
      for (let i = 0; i < english[equipmentName].length; i++) {
        listEquipment += "<li>" + english[equipmentName][i] + "</li>"
      }
    } else {
      for (let i = 0; i < french[equipmentName].length; i++) {
        listEquipment += "<li>" + french[equipmentName][i] + "</li>"
      }
    }
    
    return (
      <>
      <ul className="list-equipment">
        {parse(listEquipment)}
      </ul>
      </>
    );
  }
}

export default ListEquipment;