import React from "react";
import "../body/GeneralOverview.css";
import "../body/Enzymes.css";
import "../body/PlasmidDesign.css";
import "../protocols/AutoinductionBody.css";
import Reference from "../Reference";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ListEquipment from "../ListEquipment";
import WaveContainer from "../primitive/WaveContainer";
import layeredWave from "../../images/LayeredWaveDarkBlue.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import { Image, Button } from 'react-bootstrap'; 
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class Enzymes extends React.Component {
  render() { 
    return (
        <>
        <div className="overview-title">
            <TitleContainer text={this.props.english ? english.title_pd : french.title_pd} />
        </div>
        <div className="layered-wave">
            <Image src={layeredWave} />
        </div>
        <SubtitleContainer text={this.props.english ? parse(english.subtitle_pd_plasmids) : parse(french.subtitle_pd_plasmids)} />
        <TextContainer text={this.props.english ? parse(english.para_pd_plasmids1) : parse(french.para_pd_plasmids1)} />
        {this.props.fileDownload}
        <TextContainer text={this.props.english ? parse(english.para_pd_plasmids2) : parse(french.para_pd_plasmids2)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_plasmids2_step1) : parse(french.para_pd_plasmids2_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_plasmids2_step2) : parse(french.para_pd_plasmids2_step2)}</li>
            <li>{this.props.english ? parse(english.para_pd_plasmids2_step3) : parse(french.para_pd_plasmids2_step3)}</li>
        </ol>
        <WaveContainer image={topWave} />
        <div className="pd-overview-section-blue">
            <SubtitleContainer text={this.props.english ? english.subtitle_pd_plasmiddesign : french.subtitle_pd_plasmiddesign} />
            <TextContainer text={this.props.english ? parse(english.para_pd_plasmiddesign1) : parse(french.para_pd_plasmiddesign1)} />
            <ol className="pd-list-body">
                <li>{this.props.english ? parse(english.para_pd_plasmiddesign1_step1) : parse(french.para_pd_plasmiddesign1_step1)}</li>
                <li>{this.props.english ? parse(english.para_pd_plasmiddesign1_step2) : parse(french.para_pd_plasmiddesign1_step2)}</li>
                <li>{this.props.english ? parse(english.para_pd_plasmiddesign1_step3) : parse(french.para_pd_plasmiddesign1_step3)}</li>
                <li>{this.props.english ? parse(english.para_pd_plasmiddesign1_step4) : parse(french.para_pd_plasmiddesign1_step4)}</li>
            </ol>
        </div>
        <WaveContainer image={bottomWave} />
        <TitleContainer text={this.props.english ? english.subtitle_pd_plasmidconstruction : french.subtitle_pd_plasmidconstruction} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_amplification : french.subtitle_pd_amplification} />
        <TextContainer text={this.props.english ? parse(english.para_pd_plasmidconstruction1) : parse(french.para_pd_plasmidconstruction1)} />
        <TextContainer text={this.props.english ? parse(english.para_pd_plasmidconstruction2) : parse(french.para_pd_plasmidconstruction2)} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_primerdesign : french.subtitle_pd_primerdesign} />
        <TextContainer text={this.props.english ? parse(english.para_pd_primerdesign1) : parse(french.para_pd_primerdesign1)} />
        <TextContainer text={this.props.english ? parse(english.para_pd_primerdesign2) : parse(french.para_pd_primerdesign2)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_primerdesign2_step1) : parse(french.para_pd_primerdesign2_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_primerdesign2_step2) : parse(french.para_pd_primerdesign2_step2)}</li>
            <li>{this.props.english ? parse(english.para_pd_primerdesign2_step3) : parse(french.para_pd_primerdesign2_step3)}</li>
        </ol>
        <div className="pd-tip-container">
            <SubtitleContainer text={this.props.english ? parse(english.para_pd_primerdesign2_tip) : parse(french.para_pd_primerdesign2_tip)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_primerdesign2_tip1) : parse(french.para_pd_primerdesign2_tip1)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_primerdesign2_tip2) : parse(french.para_pd_primerdesign2_tip2)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_primerdesign2_tip3) : parse(french.para_pd_primerdesign2_tip3)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_primerdesign2_tip4) : parse(french.para_pd_primerdesign2_tip4)} />
        </div>
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_reactionsetup : french.subtitle_pd_reactionsetup} />
        <TextContainer text={this.props.english ? parse(english.para_pd_reactionsetup1) : parse(french.para_pd_reactionsetup1)} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_thermocycling : french.subtitle_pd_thermocycling} />
        <TextContainer text={this.props.english ? parse(english.para_pd_thermocycling1) : parse(french.para_pd_thermocycling1)} />
        <div className="pd-tip-container">
            <SubtitleContainer text={this.props.english ? parse(english.para_pd_thermocycling_tip) : parse(french.para_pd_thermocycling_tip)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_thermocycling_tip1) : parse(french.para_pd_thermocycling_tip1)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_thermocycling_tip2) : parse(french.para_pd_thermocycling_tip2)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_thermocycling_tip3) : parse(french.para_pd_thermocycling_tip3)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_thermocycling_tip4) : parse(french.para_pd_thermocycling_tip4)} />
            <TextContainer text={this.props.english ? parse(english.para_pd_thermocycling_tip5) : parse(french.para_pd_thermocycling_tip5)} />
        </div>
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_agarose : french.subtitle_pd_agarose} />
        <TextContainer text={this.props.english ? parse(english.para_pd_agarose1) : parse(french.para_pd_agarose1)} />
        <TextContainer text={this.props.english ? parse(english.para_pd_agarose2) : parse(french.para_pd_agarose2)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_agarose2_step1) : parse(french.para_pd_agarose2_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_agarose2_step2) : parse(french.para_pd_agarose2_step2)}</li>
            <li>{this.props.english ? parse(english.para_pd_agarose2_step3) : parse(french.para_pd_agarose2_step3)}</li>
        </ol>
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_purification : french.subtitle_pd_purification} />
        <TextContainer text={this.props.english ? parse(english.para_pd_purification1) : parse(french.para_pd_purification1)} />
        <WaveContainer image={topWave} />
        <div className="pd-overview-section-blue">
            <SubtitleContainer text={this.props.english ? english.subtitle_pd_digestion : french.subtitle_pd_digestion} />
            <TextContainer text={this.props.english ? parse(english.para_pd_digestion1) : parse(french.para_pd_digestion1)} />
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_assembly : french.subtitle_pd_assembly} />
        <TextContainer text={this.props.english ? parse(english.para_pd_assembly1) : parse(french.para_pd_assembly1)} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_gibson : french.subtitle_pd_gibson} />
        <TextContainer text={this.props.english ? parse(english.para_pd_gibson1) : parse(french.para_pd_gibson1)} />
        <TextContainer text={this.props.english ? parse(english.para_pd_gibson2) : parse(french.para_pd_gibson2)} />
        <ul className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_gibson3) : parse(french.para_pd_gibson3)}</li>
        </ul>
        <div className="plasmid-button-container">
            <a href="https://www.youtube.com/watch?v=tlVbf5fXhp4" target="blank_">
                <Button variant="primary" className="plasmid-button">
                    {this.props.english ? english.phrase_pd_video : french.phrase_pd_video}
                </Button>
            </a>
        </div>
        <TextContainer text={this.props.english ? parse(english.para_pd_gibson4) : parse(french.para_pd_gibson4)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_gibson4_step1) : parse(french.para_pd_gibson4_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_gibson4_step2) : parse(french.para_pd_gibson4_step2)}</li>
            <li>{this.props.english ? parse(english.para_pd_gibson4_step3) : parse(french.para_pd_gibson4_step3)}</li>
        </ol>
        <TextContainer text={this.props.english ? parse(english.para_pd_gibson5) : parse(french.para_pd_gibson5)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_gibson5_step1) : parse(french.para_pd_gibson5_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_gibson5_step2) : parse(french.para_pd_gibson5_step2)}</li>
            <li>{this.props.english ? parse(english.para_pd_gibson5_step3) : parse(french.para_pd_gibson5_step3)}</li>
        </ol>
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_goldengate : french.subtitle_pd_goldengate} />
        <TextContainer text={this.props.english ? parse(english.para_pd_goldengate1) : parse(french.para_pd_goldengate1)} />
        <TextContainer text={this.props.english ? parse(english.para_pd_goldengate2) : parse(french.para_pd_goldengate2)} />
        <ul className="pd-list-body">
            <li>
                {this.props.english ? parse(english.para_pd_goldentgate3) : parse(french.para_pd_goldentgate3)}
            </li>
        </ul>
        <div className="plasmid-button-container">
            <a href="https://www.youtube.com/watch?v=xusiavAC_Xk&list=PLvzzMEb3Zsn-n-ItduNGJzghAJsgsnd4Q" target="blank_">
                <Button variant="primary" className="plasmid-button">
                    {this.props.english ? english.phrase_pd_video : french.phrase_pd_video}
                </Button>
            </a>
        </div>
        <TextContainer text={this.props.english ? parse(english.para_pd_goldengate4) : parse(french.para_pd_goldengate4)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_goldengate4_step1) : parse(french.para_pd_goldengate4_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_goldengate4_step2) : parse(french.para_pd_goldengate4_step2)}</li>
            <li>{this.props.english ? parse(english.para_pd_goldengate4_step3) : parse(french.para_pd_goldengate4_step3)}</li>
        </ol>
        <TextContainer text={this.props.english ? parse(english.para_pd_goldengate5) : parse(french.para_pd_goldengate5)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_goldengate5_step1) : parse(french.para_pd_goldengate5_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_goldengate5_step2) : parse(french.para_pd_goldengate5_step2)}</li>
        </ol>
        <TextContainer text={this.props.english ? parse(english.para_pd_goldengate6) : parse(french.para_pd_goldengate6)} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_restriction : french.subtitle_pd_restriction} />
        <TextContainer text={this.props.english ? parse(english.para_pd_restriction1) : parse(french.para_pd_restriction1)} />
        <TextContainer text={this.props.english ? parse(english.para_pd_restriction2) : parse(french.para_pd_restriction2)} />
        <ul className="pd-list-body">
            <li>
                {this.props.english ? parse(english.para_pd_restriction3) : parse(french.para_pd_restriction3)}
            </li>
        </ul>
        <div className="plasmid-button-container">
            <a href="https://youtu.be/b9Lm2TUDN2M" target="blank_">
                <Button variant="primary" className="plasmid-button">
                    {this.props.english ? english.phrase_pd_video : french.phrase_pd_video}
                </Button>
            </a>
        </div>
        <TextContainer text={this.props.english ? parse(english.para_pd_restriction4) : parse(french.para_pd_restriction4)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_restriction4_step1) : parse(french.para_pd_restriction4_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_restriction4_step2) : parse(french.para_pd_restriction4_step2)}</li>
        </ol>
        <TextContainer text={this.props.english ? parse(english.para_pd_restriction5) : parse(french.para_pd_restriction5)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_restriction5_step1) : parse(french.para_pd_restriction5_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_restriction5_step2) : parse(french.para_pd_restriction5_step2)}</li>
        </ol>
        <WaveContainer image={topWave} />
        <div className="pd-overview-section-blue">
            <SubtitleContainer text={this.props.english ? english.subtitle_pd_transformation : french.subtitle_pd_transformation} />
            <TextContainer text={this.props.english ? parse(english.para_pd_transformation1) : parse(french.para_pd_transformation1)} />
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_colony : french.subtitle_pd_colony} />
        <TextContainer text={this.props.english ? parse(english.para_pd_colony) : parse(french.para_pd_colony)} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_primerdesign2 : french.subtitle_pd_primerdesign2} />
        <TextContainer text={this.props.english ? parse(english.para_pd_primerdesign21) : parse(french.para_pd_primerdesign21)} />
        <TextContainer text={this.props.english ? parse(english.para_pd_primerdesign22) : parse(french.para_pd_primerdesign22)} />
        <ol className="pd-list-body">
            <li>{this.props.english ? parse(english.para_pd_primerdesign22_step1) : parse(french.para_pd_primerdesign22_step1)}</li>
            <li>{this.props.english ? parse(english.para_pd_primerdesign22_step2) : parse(french.para_pd_primerdesign22_step2)}</li>
        </ol>
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_reactionsetup2 : french.subtitle_pd_reactionsetup2} />
        <TextContainer text={this.props.english ? parse(english.para_pd_reactionsetup21) : parse(french.para_pd_reactionsetup21)} />
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_thermocycling2 : french.subtitle_pd_thermocycling2} />
        <TextContainer text={this.props.english ? parse(english.para_pd_thermocycling21) : parse(french.para_pd_thermocycling21)} />
        <WaveContainer image={topWave} />
        <div className="pd-overview-section-blue">
            <SubtitleContainer text={this.props.english ? english.subtitle_pd_sequencing : french.subtitle_pd_sequencing} />
            <TextContainer text={this.props.english ? parse(english.para_pd_sequencing) : parse(french.para_pd_sequencing)} />
        </div>
        <WaveContainer image={bottomWave} />       
        <SubtitleContainer text={this.props.english ? english.subtitle_pd_purification2 : french.subtitle_pd_purification2} />
        <TextContainer text={this.props.english ? parse(english.para_pd_purification21) : parse(french.para_pd_purification21)} />
        <WaveContainer image={topWave} />
        <div className="overview-section-blue">
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_materials : french.subtitle_protocol_materials} />
                    <ListEquipment english={this.props.english} equipment={"para_pd_materials"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_equipment : french.subtitle_protocol_equipment} />
                    <ListEquipment english={this.props.english} equipment={"para_pd_equipment"} />
                </div>
            </div>
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? english.subtitle_go_references : french.subtitle_go_references} />
        <Reference references={"para_pd_references"} />
        </>
    );
  }
}

export default Enzymes;