import React from "react";
import "../body/Protocol.css";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import WaveContainer from "../primitive/WaveContainer";
import VideoContainer from "../primitive/VideoContainer";
import Reference from "../Reference";
import layeredWave from "../../images/LayeredWaveDarkBlue.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import { Image } from 'react-bootstrap'; 
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";

class Protocol extends React.Component {
  render(){
    const titleName = this.props.title;
    
    return (
      <>
        <div className="protocol-title">
          <TitleContainer text={this.props.english ? english[titleName] : french[titleName]} />
        </div>
        <div className="layered-wave">
          <Image src={layeredWave} />
        </div>
        {this.props.introduction}
        {this.props.fileDownload}
        <WaveContainer image={topWave} />
        <div className="protocol-blue-section">
          {this.props.body}
          {this.props.video ? <VideoContainer embedId={this.props.video} /> : <></>}
          {this.props.proof}
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? english.subtitle_go_references : french.subtitle_go_references} />
        <Reference references={this.props.references} />
      </>
    );
  }
}

export default Protocol;