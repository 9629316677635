import React from 'react'
import Routes from './Routes';
import {Router} from "react-router-dom"
import Header from './components/Header'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop';
import './App.css'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            english: true
        }
    }

    setEnglish = () => {
        this.setState({english: true})
    }

    setFrench = () => {
        this.setState({english: false})
    }

    render() {
        return (
            <div className="main-container">
                <Router history={this.props.history}>
                    <ScrollToTop />
                    <Header 
                        english={this.state.english} 
                        setEnglish={this.setEnglish} 
                        setFrench={this.setFrench} 
                    />
                    <Routes english={this.state.english} />
                    <Footer english={this.state.english} />
                </Router>
            </div>
        )
    }
}

export default App