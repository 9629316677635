import React from "react";
import "../components/Footer.css";
import { Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import LogoRedWhite from "../images/LogoRedWhite.svg";
import UniLogo from "../images/UniLogo.png";
import english from "../text_files/english.json";
import french from "../text_files/french.json";

class Footer extends React.Component {
    render() {
        return (
            <>
            <div className="footer">
                <div className="links-section">
                    <div>
                        <div className="section-title">
                            <Link className="link" to="/general-presentation">
                                {this.props.english ? english.phrase_toolbar_getstarted : french.phrase_toolbar_getstarted}
                            </Link>
                        </div>
                        <ul className="pages-footer">
                            <li>
                                <Link className="link" to="/recombinant-enzymes">
                                    {this.props.english ? english.phrase_toolbar_manuenzymes : french.phrase_toolbar_manuenzymes}
                                </Link>
                            </li>
                            <li>
                                <Link className="link" to="/CRISPR-based-biosensing">
                                    {this.props.english ? english.phrase_toolbar_CRISPR : french.phrase_toolbar_CRISPR}
                                </Link>
                            </li>
                            <li>
                                <Link className="link" to="/glossary">
                                    {this.props.english ? english.phrase_toolbar_glossary : french.phrase_toolbar_glossary}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="section-title">
                            <Link className="link" to="/protocols-overview">
                                {this.props.english ? english.phrase_toolbar_protocols : french.phrase_toolbar_protocols}
                            </Link>
                        </div>
                        <div className="protocols-wrapper">
                            <ul className="pages-footer">
                                <li>
                                    <Link className="link" to="/how-to-plasmids">
                                        {this.props.english ? english.phrase_toolbar_howto : french.phrase_toolbar_howto}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/autoinduction-scale-up">
                                        {this.props.english ? english.phrase_toolbar_autoinduction : french.phrase_toolbar_autoinduction}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/cell-free-expression">
                                        {this.props.english ? english.phrase_toolbar_cellfree : french.phrase_toolbar_cellfree}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/crude-extract-preparation">
                                        {this.props.english ? english.phrase_toolbar_crude : french.phrase_toolbar_crude}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/biosensor">
                                        {this.props.english ? english.phrase_toolbar_biosensor : french.phrase_toolbar_biosensor}
                                    </Link>
                                </li>
                            </ul>
                            <ul className="pages-footer">
                                <li>
                                    <Link className="link" to="/restriction-enzymes">
                                        {this.props.english ? english.phrase_toolbar_restriction : french.phrase_toolbar_restriction}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/plasmid-design">
                                        {this.props.english ? english.phrase_toolbar_plasmid : french.phrase_toolbar_plasmid}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/autolysis-and-autohydrolysis">
                                        {this.props.english ? english.phrase_toolbar_hydro : french.phrase_toolbar_hydro}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/bioinformatics">
                                        {this.props.english ? english.phrase_toolbar_bioinfo : french.phrase_toolbar_bioinfo}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/how-to-set-up-a-lab">
                                        {this.props.english ? english.phrase_toolbar_labsetup : french.phrase_toolbar_labsetup}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="section-title">
                            {this.props.english ? english.phrase_footer_connect : french.phrase_footer_connect}
                        </div>
                        <ul className="pages-footer">
                            <li>
                                <Link className="link" to="/about">
                                    {this.props.english ? english.phrase_toolbar_about : french.phrase_toolbar_about}
                                </Link>
                            </li>
                            <li>
                                <Link className="link" to="/contact">
                                    {this.props.english ? english.phrase_toolbar_contact : french.phrase_toolbar_contact}
                                </Link>
                            </li>
                        </ul>
                    </div>                        
                </div>
                <div className="belowline-footer">
                        <Link to="/">
                            <Image className="group-logo-footer" src={LogoRedWhite} alt="Group Logo" />
                        </Link>
                        <Image className="uni-logo" src={UniLogo} alt="Uni Logo" />
                        {this.props.english ? english.phrase_footer_projectdesc : french.phrase_footer_projectdesc} 
                        <br/>
                        &copy; 2021 &nbsp;
                        {this.props.english ? english.phrase_footer_copyrights : french.phrase_footer_copyrights}
                </div>
            </div>
            </>
        );
    }
}

export default Footer;