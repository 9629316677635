import React from "react";
import "./TextContainer.css";

class TextContainer extends React.Component {
  render() {
    return (
      <div className="text-container">
        {this.props.text}
      </div>
    );
  }
}

export default TextContainer;