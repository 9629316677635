import React from "react";
import "../protocols/AutoinductionBody.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import ListEquipment from "../ListEquipment";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class HowtoBody extends React.Component {
    render() {
        return(
            <>
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_materials : french.subtitle_protocol_materials} />
                    <ListEquipment english={this.props.english} equipment={"para_howto_materials"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_equipment : french.subtitle_protocol_equipment} />
                    <ListEquipment english={this.props.english} equipment={"para_howto_equipment"} />
                </div>
            </div>
            <SubtitleContainer text={this.props.english ? english.subtitle_protocol_protocol : french.subtitle_protocol_protocol} />
            <ol className="list-body-precipitation">
                <li>{this.props.english ? parse(english.para_howto_step1) : parse(french.para_howto_step1)}</li>
                <li>{this.props.english ? parse(english.para_howto_step2) : parse(french.para_howto_step2)}</li>
                <li>{this.props.english ? parse(english.para_howto_step3) : parse(french.para_howto_step3)}</li>
                <li>{this.props.english ? parse(english.para_howto_step4) : parse(french.para_howto_step4)}</li>
                <li>{this.props.english ? parse(english.para_howto_step5) : parse(french.para_howto_step5)}</li>
            </ol>
            </>
        );
    }
}

export default HowtoBody;