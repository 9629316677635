import React from "react";
import "../protocols/AutoinductionBody.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextImageContainer from "../primitive/TextImageContainer";
import TextContainer from "../primitive/TextContainer";
import ListEquipment from "../ListEquipment";
import TipProtocol from "../TipProtocol";
import CrudeFigure1 from "../../images/CrudeFigure1.jpeg";
import CrudeFigure2 from "../../images/CrudeFigure2.jpeg";
import CrudeFigure3 from "../../images/CrudeFigure3.jpeg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class AutoinductionBody extends React.Component {
    render() {
        return(
            <>
            <SubtitleContainer text={this.props.english ? english.subtitle_crude_protocol : french.subtitle_crude_protocol} />
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_materials : french.subtitle_protocol_materials} />
                    <ListEquipment english={this.props.english} equipment={"para_crude_materials"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_equipment : french.subtitle_protocol_equipment} />
                    <ListEquipment english={this.props.english} equipment={"para_crude_equipment"} />
                </div>
            </div>
            <SubtitleContainer text={this.props.english ? english.subtitle_protocol_protocol : french.subtitle_protocol_protocol} />
            <ol className="list-body-precipitation">
                <li>{this.props.english ? parse(english.para_crude_step1) : parse(french.para_crude_step1)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_crude_step1_tip) : parse(french.para_crude_step1_tip)} />
                <li>{this.props.english ? english.para_crude_step2 : french.para_crude_step2}</li>
                <li>{this.props.english ? parse(english.para_crude_step3) : parse(french.para_crude_step3)}</li>
                <li>{this.props.english ? parse(english.para_crude_step4) : parse(french.para_crude_step4)}</li>
                <li>{this.props.english ? english.para_crude_step5 : french.para_crude_step5}</li>
                <li>{this.props.english ? english.para_crude_step6 : french.para_crude_step6}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_crude_step6_tip1) : parse(french.para_crude_step6_tip2)} />
                <li>{this.props.english ? parse(english.para_crude_step7) : parse(french.para_crude_step7)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? parse(english.para_crude_step7_tip) : parse(french.para_crude_step7_tip)} />
                <TextContainer text={this.props.english ? parse(english.para_crude_transition) : parse(french.para_crude_transition)} />
            </ol>
            <SubtitleContainer text={this.props.english ? parse(english.subtitle_crude_celllysate) : parse(french.subtitle_crude_celllysate)} />
            <ol className="list-body-precipitation" start="8">
                <li>{this.props.english ? english.para_crude_step8 : french.para_crude_step8}</li>
                <li>{this.props.english ? english.para_crude_step9 : french.para_crude_step9}</li>
                <li>{this.props.english ? english.para_crude_step10 : french.para_crude_step10}</li>
                <li>{this.props.english ? english.para_crude_step11 : french.para_crude_step11}</li>
                <li>{this.props.english ? english.para_crude_step12 : french.para_crude_step12}</li>
                <TextContainer text={this.props.english ? parse(english.para_crude_protocol_text) : parse(french.para_crude_protocol_text)} />
            </ol>
            <SubtitleContainer text={this.props.english ? parse(english.subtitle_crude_cellreagent) : parse(french.subtitle_crude_cellreagent)} />
            <ol className="list-body-precipitation" start="8">
                <li>{this.props.english ? parse(english.para_crude_step8_bis) : parse(french.para_crude_step8_bis)}</li>
                <li>{this.props.english ? english.para_crude_step9_bis : french.para_crude_step9_bis}</li>
                <li>{this.props.english ? english.para_crude_step10_bis : french.para_crude_step10_bis}</li>
                <li>{this.props.english ? english.para_crude_step11_bis : french.para_crude_step11_bis}</li>
                <TextContainer text={this.props.english ? parse(english.para_crude_step11_bis_note) : parse(french.para_crude_step11_bis_note)} />
            </ol>
            <TextImageContainer textLeft={false} text={this.props.english ? parse(english.para_crude_figure1) : parse(french.para_crude_figure1)} image={CrudeFigure1} />
            <TextImageContainer textLeft={false} text={this.props.english ? parse(english.para_crude_figure2) : parse(french.para_crude_figure2)} image={CrudeFigure2} />
            <TextImageContainer textLeft={false} text={this.props.english ? parse(english.para_crude_figure3) : parse(french.para_crude_figure3)} image={CrudeFigure3} />
            </>
        );
    }
}

export default AutoinductionBody;