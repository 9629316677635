import React from "react";
import "../protocols/CellFreeIntro.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import CellFree_Recombinant from "../../images/CellFree_Recombinant.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class CellFreeIntro extends React.Component {
    render() {
        return(
            <>
            <SubtitleContainer text={this.props.english ? english.subtitle_protocol_intro : french.subtitle_protocol_intro} />
            <TextContainer text={this.props.english ? parse(english.para_cellfree_intro1) : parse(french.para_cellfree_intro1)} />
            <TextContainer text={this.props.english ? parse(english.para_cellfree_intro2) : parse(french.para_cellfree_intro2)} />
            <TextContainer text={this.props.english ? parse(english.para_cellfree_intro3) : parse(french.para_cellfree_intro3)} />
            <div className="cellfree-recomb">
                <ImageContainer image={CellFree_Recombinant} />
            </div>
            </>
        );
    }
}

export default CellFreeIntro;