import React from "react";
import "./SubtitleContainer.css";

class SubtitleContainer extends React.Component {
  render() {
    return (
      <div className="subtitle-container">
        {this.props.text}
      </div>
    );
  }
}

export default SubtitleContainer;