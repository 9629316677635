import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'
import createHistory from 'history/createBrowserHistory'
const history = createHistory()

ReactDOM.render(
  <React.StrictMode>
    <App history={history}/>
  </React.StrictMode>,
  document.getElementById("root")
);