import React from "react";
import "../body/Protocol.css";
import { Button } from 'react-bootstrap'; 
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";

class DownloadProtocolButton extends React.Component {
  render() {  
    return (
        <>
        <div className="protocol-button-container">
            <a href={this.props.english ? this.props.EnglishFile : this.props.FrenchFile} download="Protocol.pdf">
                <Button variant="primary" className="protocol-button">
                    {this.props.english ? english.phrase_protocol_download : french.phrase_protocol_download}
                </Button>
            </a>
        </div>
        </>
    ); 
  }
}

export default DownloadProtocolButton;