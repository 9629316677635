import React from "react";
import "../body/GeneralOverview.css";
import "../body/Enzymes.css";
import Reference from "../Reference";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import WaveContainer from "../primitive/WaveContainer";
import layeredWave from "../../images/LayeredWaveDarkBlue.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import { Image } from 'react-bootstrap'; 
import RecombinantEnzymes from "../../images/RecombinantEnzymes.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class Enzymes extends React.Component {
  render() { 
    return (
      <>
      <div className="overview-title">
        <TitleContainer text={this.props.english ? english.title_go_enzymes : french.title_go_enzymes} />
      </div>
      <div className="layered-wave">
        <Image src={layeredWave} />
      </div>
      <SubtitleContainer text={this.props.english ? english.subtitle_enz_intro : french.subtitle_enz_intro} />
      <TextContainer text={parse(this.props.english ? english.para_enz_intro1 : french.para_enz_intro1)} />
      <TextContainer text={parse(this.props.english ? english.para_enz_intro2 : french.para_enz_intro2)} />
      <TextContainer text={parse(this.props.english ? english.para_enz_intro3 : french.para_enz_intro3)} />
      <div className="recombinant-enzymes-image-container" >
        <ImageContainer image={RecombinantEnzymes} />
      </div>
      <WaveContainer image={topWave} />
      <div className="overview-section-blue">
        <TextContainer text={parse(this.props.english ? english.para_enz_intro4 : french.para_enz_intro4)} />
      </div>
      <WaveContainer image={bottomWave} />
      <SubtitleContainer text={this.props.english ? english.subtitle_go_references : french.subtitle_go_references} />
      <Reference references={"para_enz_references"} />
      </>
    );
  }
}

export default Enzymes;