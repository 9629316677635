import React from "react";
import "./ContactListItem.css";
import english from "../text_files/english.json";
import french from "../text_files/french.json";
import parse from 'html-react-parser';

class ContactListItem extends React.Component {
    render() {
        const contact = this.props.contactItem;
        let contactName = this.props.english ? english[contact][0] : french[contact][0];
        let listContactDetails = "";
        
        if (this.props.english) {
            for (let i = 1; i < english[contact].length; i++) {
                listContactDetails += "<li>" + english[contact][i] + "</li>"
            }
        } else {
            for (let i = 1; i < french[contact].length; i++) {
                listContactDetails += "<li>" + french[contact][i] + "</li>"
            }
        }

        return (
            <>
            <div className="contact-wrapper">
                <div className="contact-name">
                    {contactName}
                </div>
                <ul className="contact-details">
                    {parse(listContactDetails)}
                </ul>
            </div>
            </>
        );
    }
}
            
export default ContactListItem;