import React from "react";
import "./TitleContainer.css";

class TitleContainer extends React.Component {
  render() {
    return (
      <div className="title-container">
        {this.props.text}
      </div>
    );
  }
}

export default TitleContainer;