import React from "react";
import "./Header.css";
import Toolbar from "../components/Toolbar";
import LanguageSwitch from "./LanguageSwitch";
import LogoRedWhite from "../images/LogoRedWhite.svg";
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom"

class Header extends React.Component { 
    render() {
        return (
            <>
            <div className="header">
                <Row>
                    <Col sm={3}>
                        <Link to="/">
                            <Image className="group-logo" src={LogoRedWhite} alt="Group Logo" fluid /> 
                        </Link>
                    </Col>
                    <Col className="toolbar">
                        <Toolbar english={this.props.english} />
                    </Col>
                    <Col className="language-switch" sm={1}>
                        <LanguageSwitch setEnglish={this.props.setEnglish} setFrench={this.props.setFrench}/> 
                    </Col> 
                </Row>
            </div>
            </>
        );
    }
}

export default Header;