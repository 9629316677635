import React from "react";
import "./GetStarted.css";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextImageContainer from "../../components/primitive/TextImageContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import WaveContainer from "../primitive/WaveContainer";
import CircularDesign from "../../images/CircularDesign.svg";
import JuHyunLab from "../../images/JuHyunLab.png";
import HaoLab from "../../images/HaoLab.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";

class GetStarted extends React.Component {
  render() {
    return (
      <>
      <TitleContainer text={this.props.english ? english.title_gs_presentation : french.title_gs_presentation} />
      <SubtitleContainer text={this.props.english ? english.subtitle_gs_toolkit : french.subtitle_gs_toolkit} />
      <TextContainer text={this.props.english ? english.para_gs_toolkit1 : french.para_gs_toolkit1} />
      <TextContainer text={this.props.english ? english.para_gs_toolkit2 : french.para_gs_toolkit2} />
      <WaveContainer image={topWave} />    
      <div className="getstarted-blue-section">
        <TextImageContainer 
          textLeft={false} 
          subtitle={this.props.english ? english.subtitle_gs_enzymes : french.subtitle_gs_enzymes} 
          text={this.props.english ? english.para_gs_enzymes : french.para_gs_enzymes} 
          image={JuHyunLab} 
        />
      </div>
      <div className="getstarted-blue-section">
        <TextImageContainer 
          textLeft={true} 
          subtitle={this.props.english ? english.subtitlte_gs_CRISPR : french.subtitlte_gs_CRISPR} 
          text={this.props.english ? english.para_gs_CRISPR : french.para_gs_CRISPR} 
          image={HaoLab} 
        />
      </div>
      <WaveContainer image={bottomWave} />
      <SubtitleContainer text={this.props.english ? english.subtitle_gs_circulardesign : french.subtitle_gs_circulardesign} />
      <TextContainer text={this.props.english ? english.para_gs_circulardesign : french.para_gs_circulardesign} />
      <div className="large-image-container" >
        <ImageContainer image={CircularDesign} /> 
      </div>
      </>
    );
  }
}
  
export default GetStarted;