import React from "react";
import "../body/GeneralOverview.css";
import "../body/Enzymes.css";
import "../body/StartLab.css";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import WaveContainer from "../primitive/WaveContainer";
import ListEquipment from "../ListEquipment";
import layeredWave from "../../images/LayeredWaveDarkBlue.png";
import topWave from "../../images/TopWaveLightBlue.svg";
import bottomWave from "../../images/BottomWaveLightBlue.svg";
import { Image } from 'react-bootstrap';  
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class StartLab extends React.Component {
  render() { 
    return (
        <>
        <div className="overview-title">
            <TitleContainer text={this.props.english ? english.title_lab : french.title_lab} />
        </div>
        <div className="layered-wave">
            <Image src={layeredWave} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_lab_intro1) : parse(french.para_lab_intro1)} />
        <TextContainer text={this.props.english ? parse(english.para_lab_intro2) : parse(french.para_lab_intro2)} />
        {this.props.fileDownload}
        <WaveContainer image={topWave} />
        <div className="pd-overview-section-blue">
            <SubtitleContainer text={this.props.english ? parse(english.subtitle_lab_equipment) : parse(french.subtitle_lab_equipment)} />
            <TextContainer text={this.props.english ? parse(english.para_lab_eq1) : parse(french.para_lab_eq1)} />
            <div className="lab-list">
                <ListEquipment english={this.props.english} equipment={"para_lab_eq1list"} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_lab_eq2) : parse(french.para_lab_eq2)} />
            <div className="lab-list">
                <ListEquipment english={this.props.english} equipment={"para_lab_eq2list"} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_lab_eq3) : parse(french.para_lab_eq3)} />
            <div className="lab-list">
                <ListEquipment english={this.props.english} equipment={"para_lab_eq3list"} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_lab_eq4) : parse(french.para_lab_eq4)} />
            <TextContainer text={this.props.english ? parse(english.para_lab_eq5) : parse(french.para_lab_eq5)} />
            <div className="lab-list">
                <ListEquipment english={this.props.english} equipment={"para_lab_eq5list"} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_lab_eq6) : parse(french.para_lab_eq6)} />
            <div className="lab-list">
                <ListEquipment english={this.props.english} equipment={"para_lab_eq6list"} />
            </div>
        </div>
        <WaveContainer image={bottomWave} />
        <SubtitleContainer text={this.props.english ? parse(english.subtitle_lab_material) : parse(french.subtitle_lab_material)} />
        <TextContainer text={this.props.english ? parse(english.para_lab_mat1) : parse(french.para_lab_mat1)} />
        <div className="lab-list">
            <ListEquipment english={this.props.english} equipment={"para_lab_mat1list"} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_lab_mat2) : parse(french.para_lab_mat2)} />
        <div className="lab-list">
            <ListEquipment english={this.props.english} equipment={"para_lab_mat2list"} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_lab_mat3) : parse(french.para_lab_mat3)} />
        <div className="lab-list">
            <ListEquipment english={this.props.english} equipment={"para_lab_mat3list"} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_lab_mat4) : parse(french.para_lab_mat4)} />
        <div className="lab-list">
            <ListEquipment english={this.props.english} equipment={"para_lab_mat4list"} />
        </div>
        <TextContainer text={this.props.english ? parse(english.para_lab_mat5) : parse(french.para_lab_mat5)} />
        <div className="lab-list">
            <ListEquipment english={this.props.english} equipment={"para_lab_mat5list"} />
        </div>
        </>
    );
  }
}

export default StartLab;