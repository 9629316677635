import React from "react";
import "../components/SlideHover.css";
import LogoWrapper from "../components/LogoWrapper";
import EBTILogo from "../images/EBTILogo.jpg";
import KenyattaUni from "../images/KenyattaUni.png";
import HiveBioLab from "../images/HiveBioLab.jpg";
import NairobiUni from "../images/NairobiUni.jfif";
import MboaLab from "../images/LogoMboaLab.png";
import ISAAA from "../images/ISAAA.svg";
import english from "../text_files/english.json";
import french from "../text_files/french.json";

class SlideHover extends React.Component {
    render() { 
        return (
        <>
        <div className="squares-container">
            <LogoWrapper 
                logo={HiveBioLab} 
                title={this.props.english ? english.subtitle_about_HiveBioLab : french.subtitle_about_HiveBioLab} 
                description={this.props.english ? english.para_about_HiveBioLab : french.para_about_HiveBioLab} 
            />
            <LogoWrapper 
                logo={NairobiUni} 
                title={this.props.english ? english.subtitle_about_NairobiUni : french.subtitle_about_NairobiUni} 
                description={this.props.english ? english.para_about_NairobiUni : french.para_about_NairobiUni} 
            />
            <LogoWrapper 
                logo={MboaLab} 
                title={this.props.english ? english.subtitle_about_MboaLab : french.subtitle_about_MboaLab} 
                description={this.props.english ? english.para_about_MboaLab : french.para_about_MboaLab} 
            />
        </div>
        <div className="squares-container">
            <LogoWrapper 
                logo={KenyattaUni} 
                title={this.props.english ? english.subtitle_about_KenyattaUni : french.subtitle_about_KenyattaUni} 
                description={this.props.english ? english.para_about_KenyattaUni : french.para_about_KenyattaUni} 
            />
            <LogoWrapper 
                logo={ISAAA} 
                title={this.props.english ? english.subtitle_about_ISAAA : french.subtitle_about_ISAAA} 
                description={this.props.english ? english.para_about_ISAAA : french.para_about_ISAAA} 
            />
            <LogoWrapper 
                logo={EBTILogo} 
                title={this.props.english ? english.subtitle_about_EBTI : french.subtitle_about_EBTI} 
                description={this.props.english ? english.para_about_EBTI : french.para_about_EBTI} 
            />
        </div>
        </>
        );
    }
}

export default SlideHover;