import React from "react";
import "./ProtocolsOverview.css";
import TitleContainer from "../primitive/TitleContainer";
import TextImageContainer from "../primitive/TextImageContainer";
import SophiaPhoto from "../../images/ProtocolsOverviewPhoto.jpeg";
import GeneralBooklet from "../../documents/GeneralBooklet.pdf";
import GeneralBookletFR from "../../documents/GeneralBooklet_FR.pdf";
import { Button } from 'react-bootstrap'; 
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class ProtocolsOverview extends React.Component {
  render() {
    return (
      <>
      <TitleContainer text={this.props.english ? english.title_po_presentation : french.title_po_presentation} />
      <TextImageContainer 
        textLeft={true}
        text={this.props.english ? parse(english.para_po_intro) : parse(french.para_po_intro)}
        image={SophiaPhoto}
      />
      <div className="po-button-container">
        <a href={this.props.english ? GeneralBooklet : GeneralBookletFR} download="GeneralBooklet.pdf">
          <Button variant="primary" className="po-button">
            {this.props.english ? english.phrase_po_download : french.phrase_po_download}
          </Button>
        </a>
      </div>
      </>
    );
  }
}
  
export default ProtocolsOverview;