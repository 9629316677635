import React from "react";
import "./ImageContainer.css";

class ImageContainer extends React.Component {
  render() {
    return (
      <div className="image-container">
        <img src={this.props.image} alt="placeholder" fluid />
      </div>
    );
  }
}

export default ImageContainer;