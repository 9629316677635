import React from "react";
import "../body/Glossary.css";
import Definition from "../Definition";
import TitleContainer from "../primitive/TitleContainer";
import SubtitleContainer from "../primitive/SubtitleContainer";
import WaveContainer from "../primitive/WaveContainer";
import Reference from "../Reference";
import WaveBottom from "../../images/BottomWaveDarkBlue.svg";
import WaveTop from "../../images/TopWaveDarkBlue.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";

class Glossary extends React.Component {
  render() { 
    return (
      <>
      <div className="glossary-wave">
        <WaveContainer image={WaveTop} />
      </div>
      <TitleContainer text={this.props.english ? english.title_glossary : french.title_glossary} />
      <Definition definitions={"para_glossary_def"} english={this.props.english} />
      <SubtitleContainer text={this.props.english ? english.subtitle_go_references : french.subtitle_go_references} />
      <Reference references={"para_enz_references"} />
      <div className="glossary-wave">
        <WaveContainer image={WaveBottom} />
      </div>
      </>
    );
  }
}

export default Glossary;