import React from "react";
import "../components/LanguageSwitch.css";
import ukFlag from "../images/UKFlag.gif";
import frFlag from "../images/FrFlag.gif";
import { Button, Container, Image } from 'react-bootstrap'; 

class LanguageSwitch extends React.Component {
    render() { 
        return (
            <>
            <Container className="flags">
                <Button className="button-flags" variant="light" onClick={() => this.props.setEnglish()}>
                    <Image className="image-flag" src={ukFlag} />
                </Button>
                <Button className="button-flags" variant="light" onClick={() => this.props.setFrench()}>
                    <Image className="image-flag" src={frFlag} />
                </Button>
            </Container>
            </>
        );
    }
}

export default LanguageSwitch;