import React from "react";
import "../body/About.css";
import SlideHover from "../SlideHover";
import AboutWrapper from "../AboutWrapper";
import TitleContainer from "../primitive/TitleContainer";
import TextContainer from "../primitive/TextContainer";
import WaveContainer from "../primitive/WaveContainer";
import TeamPhoto from "../../images/GroupPhotononcropped.jpeg";
import DepartmentNight from "../../images/DepartmentNight.png";
import WaveBottom from "../../images/BottomSlightWaveDarkBlue.svg";
import WaveTop from "../../images/TopSlightWaveDarkBlue.svg";
import english from "../../text_files/english.json"
import french from "../../text_files/french.json"

class About extends React.Component {
  render() {
    return (
      <>
      <TitleContainer text={this.props.english ? english.title_about_presentationgroup : french.title_about_presentationgroup} />
      <div className="rectangle-container">
        <AboutWrapper
          image={TeamPhoto} 
          subtitle={this.props.english ? english.subtitle_about_team : french.subtitle_about_team} 
          description={this.props.english ? english.para_about_presentationgroup : french.para_about_presentationgroup} 
        />
        <AboutWrapper
          image={DepartmentNight}
          subtitle={this.props.english ? english.subtitle_about_project : french.subtitle_about_project} 
          description={this.props.english ? english.para_about_presentationproject : french.para_about_presentationproject}
        />
      </div>
      <WaveContainer image={WaveTop} />
      <div className="mission">
        <TitleContainer text={this.props.english ? english.title_about_mission : french.title_about_mission} />
        <TextContainer text={this.props.english ? english.para_about_mission : french.para_about_mission} />
      </div>
      <WaveContainer image={WaveBottom} />     
      <div className="partners-container">
        <TitleContainer text={this.props.english ? english.title_about_stakeholders : french.title_about_stakeholders} />
        <SlideHover english={this.props.english}/>
      </div>
      </>
    );
  }
}

export default About;