import React from "react";
import { Image } from 'react-bootstrap'; 
import "../components/LogoWrapper.css";
import ContactListItem from "./ContactListItem";

class LogoWrapperContact extends React.Component {
    render() { 
        return (
        <>
        <div className="img-wrapper">
            <Image className="image-partner" src={this.props.logo} />
            <div className="text-wrapper-partner">
                <ContactListItem contactItem={this.props.contactItem} english={this.props.english} />
            </div>
        </div>
        </>
        );
    }
}

export default LogoWrapperContact;