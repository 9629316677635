import React from "react";
import "../protocols/AutoinductionIntro.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import AutoinductionBroth from "../../images/AutoinductionBroth.svg";
import AutoinductionProcess from "../../images/AutoinductionProcess.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class AutoinductionIntro extends React.Component {
    render() {
        return(
            <>
            <SubtitleContainer text={this.props.english ? english.subtitle_protocol_intro : french.subtitle_protocol_intro} />
            <TextContainer text={this.props.english ? english.para_autoinduction_intro1 : french.para_autoinduction_intro1} />
            <TextContainer text={this.props.english ? parse(english.para_autoinduction_intro2) : parse(french.para_autoinduction_intro2)} />
            <div className="autoinduction-broth">
                <ImageContainer image={AutoinductionBroth} />
            </div>
            <TextContainer text={this.props.english ? parse(english.para_autoinduction_intro3) : parse(french.para_autoinduction_intro3)} />
            <TextContainer text={this.props.english ? parse(english.para_autoinduction_intro4) : parse(french.para_autoinduction_intro4)} />
            <TextContainer text={this.props.english ? english.para_autoinduction_intro5 : french.para_autoinduction_intro5} />
            <ImageContainer image={AutoinductionProcess} />
            <TextContainer text={this.props.english ? parse(english.para_autoinduction_intro6) : parse(french.para_autoinduction_intro6)} />
            <TextContainer text={this.props.english ? parse(english.para_autoinduction_intro7) : parse(french.para_autoinduction_intro7)} />
            </>
        );
    }
}

export default AutoinductionIntro;