import React from "react";
import "../protocols/AutoinductionBody.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ListEquipment from "../ListEquipment";
import TipProtocol from "../TipProtocol";
import AutoinductionTable from "../../images/AutoinductionTable.svg";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";
import ImageContainer from "../primitive/ImageContainer";

class AutoinductionBody extends React.Component {
    render() {
        return(
            <>
            <SubtitleContainer text={this.props.english ? english.subtitle_autoinduction_protocol : french.subtitle_autoinduction_protocol} />
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_materials : french.subtitle_protocol_materials} />
                    <ListEquipment english={this.props.english} equipment={"para_autoinduction_materials"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_equipment : french.subtitle_protocol_equipment} />
                    <ListEquipment english={this.props.english} equipment={"para_autoinduction_equipment"} />
                </div>
            </div>
            <SubtitleContainer text={this.props.english ? english.subtitle_protocol_protocol : french.subtitle_protocol_protocol} />
            <ol className="list-body-precipitation">
                <li>{this.props.english ? parse(english.para_autoinduction_step1) : parse(french.para_autoinduction_step1)}</li>
                <li>{this.props.english ? english.para_autoinduction_step2 : french.para_autoinduction_step2}</li>
                <li>{this.props.english ? parse(english.para_autoinduction_step3) : parse(french.para_autoinduction_step3)}</li>
                <li>{this.props.english ? parse(english.para_autoinduction_step4) : parse(french.para_autoinduction_step4)}</li>
                <li>{this.props.english ? english.para_autoinduction_step5 : french.para_autoinduction_step5}</li>
                <TextContainer text={this.props.english ? parse(english.para_autoinduction_transition) : parse(french.para_autoinduction_transition)} />
                <li>{this.props.english ? english.para_autoinduction_step6 : french.para_autoinduction_step6}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? english.para_autoinduction_step6_tip1 : french.para_autoinduction_step6_tip1} />
                <TipProtocol english={this.props.english} text={this.props.english ? english.para_autoinduction_step6_tip2 : french.para_autoinduction_step6_tip2} />
                <TipProtocol english={this.props.english} text={this.props.english ? english.para_autoinduction_extratip1 : french.para_autoinduction_extratip1} />
                <TipProtocol english={this.props.english} text={this.props.english ? english.para_autoinduction_extratip2 : french.para_autoinduction_extratip2} />
                <li>{this.props.english ? parse(english.para_autoinduction_step7) : parse(french.para_autoinduction_step7)}</li>
                <li>{this.props.english ? english.para_autoinduction_step8 : french.para_autoinduction_step8}</li>
                <li>{this.props.english ? english.para_autoinduction_step9 : french.para_autoinduction_step9}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? english.para_autoinduction_step9_tip : french.para_autoinduction_step9_tip} />
                <li>{this.props.english ? english.para_autoinduction_step10 : french.para_autoinduction_step10}</li>
                <li>{this.props.english ? english.para_autoinduction_step11 : french.para_autoinduction_step11}</li>
                <li>{this.props.english ? parse(english.para_autoinduction_step12) : parse(french.para_autoinduction_step12)}</li>
                <div className="protocol-table">
                    <ImageContainer image={AutoinductionTable} />
                </div>
                <li>{this.props.english ? english.para_autoinduction_step13 : french.para_autoinduction_step13}</li>
            </ol>
            <TextContainer text={this.props.english ? parse(english.para_autoinduction_step14) : parse(french.para_autoinduction_step14)} />
            </>
        );
    }
}

export default AutoinductionBody;