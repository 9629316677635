import React from "react";
import "./PageContainer.css";

class PageContainer extends React.Component {
    render() {
      return (
        <div className="page-container">
          {this.props.page}
        </div>
      );
    }
}

export default PageContainer;