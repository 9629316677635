import React from "react";
import "./Definition.css";
import english from "../text_files/english.json";
import french from "../text_files/french.json";
import parse from 'html-react-parser';

class Definition extends React.Component {
  render() { 
    const definitions = this.props.definitions;
    let listDefinition = "";
    
    if (this.props.english) {
      for (let i = 0; i < english[definitions].length; i++) {
        let term = english[definitions][i].term
        let meaning = english[definitions][i].meaning
        listDefinition += "<li> <span class='term'>" + term + ":</span> " + meaning + "</li>";
      }
    }
    else {
      for (let i = 0; i < french[definitions].length; i++) {
        let term = french[definitions][i].term
        let meaning = french[definitions][i].meaning
        listDefinition += "<li> <span class='term'>" + term + ":</span> " + meaning + "</li>";
      }
    }

    return (
      <>
      <ul className="list-glossary">
        {parse(listDefinition)}
      </ul>
      </>
    );
  }
}

export default Definition;