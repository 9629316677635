import React from "react";
import "./VideoContainer.css";

class VideoContainer extends React.Component {
    render() {
        return (
            <div className="video-container">
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${this.props.embedId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        );
    }
}

export default VideoContainer;