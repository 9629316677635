import React from "react";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class HowtoIntro extends React.Component {
    render() {
        return(
            <>
            <SubtitleContainer text={this.props.english ? english.subtitle_protocol_intro : french.subtitle_protocol_intro} />
            <TextContainer text={this.props.english ? parse(english.para_howto_intro1) : parse(french.para_howto_intro1)} />
            <TextContainer text={this.props.english ? parse(english.para_howto_intro2) : parse(french.para_howto_intro2)} />
            <TextContainer text={this.props.english ? parse(english.para_howto_intro3) : parse(french.para_howto_intro3)} />
            <TextContainer text={this.props.english ? parse(english.para_howto_intro4) : parse(french.para_howto_intro4)} />
            <TextContainer text={this.props.english ? parse(english.para_howto_intro5) : parse(french.para_howto_intro5)} />
            <TextContainer text={this.props.english ? parse(english.para_howto_intro6) : parse(french.para_howto_intro6)} />
            </>
        );
    }
}

export default HowtoIntro;