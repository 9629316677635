import React from "react";
import "../protocols/CellFreeBody.css";
import "../protocols/AutoinductionBody.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextContainer from "../primitive/TextContainer";
import ImageContainer from "../primitive/ImageContainer";
import ListEquipment from "../ListEquipment";
import TipProtocol from "../TipProtocol";
import CellFree_Table1 from "../../images/CellFree_Table1.png";
import CellFree_Table2 from "../../images/CellFree_Table2.png";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class CellFreeBody extends React.Component {
    render() {
        return(
            <>
            <div className="lab-setup">
                <div className="lab-setup-materials">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_materials : french.subtitle_protocol_materials} />
                    <ListEquipment english={this.props.english} equipment={"para_cellfree_materials"} />
                </div>
                <div className="lab-setup-equipment">
                    <SubtitleContainer text={this.props.english ? english.subtitle_protocol_equipment : french.subtitle_protocol_equipment} />
                    <ListEquipment english={this.props.english} equipment={"para_cellfree_equipment"} />
                </div>
            </div>
            <SubtitleContainer text={this.props.english ? english.subtitle_cellfree_lysate : french.subtitle_cellfree_lysate} />
            <TextContainer text={this.props.english ? english.para_cellfree_lysate1 : french.para_cellfree_lysate1} />
            <ol className="list-body-precipitation">
                <li>{this.props.english ? parse(english.para_cellfree_lysate2) : parse(french.para_cellfree_lysate2)}</li>
                <li>{this.props.english ? english.para_cellfree_lysate3 : french.para_cellfree_lysate3}</li>
                <li>{this.props.english ? parse(english.para_cellfree_lysate4) : parse(french.para_cellfree_lysate4)}</li>
                <li>{this.props.english ? parse(english.para_cellfree_lysate5) : parse(french.para_cellfree_lysate5)}</li>
                <li>{this.props.english ? english.para_cellfree_lysate6 : french.para_cellfree_lysate6}</li>
                <TextContainer text={this.props.english ? parse(english.para_cellfree_lysate7) : parse(french.para_cellfree_lysate7)} />
                <li>{this.props.english ? english.para_cellfree_lysate8 : french.para_cellfree_lysate8}</li>
                <li>{this.props.english ? parse(english.para_cellfree_lysate9) : parse(french.para_cellfree_lysate9)}</li>
                <TipProtocol english={this.props.english} text={this.props.english ? english.para_cellfree_lysate9_tip : french.para_cellfree_lysate9_tip} />
                <li>{this.props.english ? english.para_cellfree_lysate10 : french.para_cellfree_lysate10}</li>
                <li>{this.props.english ? english.para_cellfree_lysate11 : french.para_cellfree_lysate11}</li>
                <li>{this.props.english ? english.para_cellfree_lysate12 : french.para_cellfree_lysate12}</li>
                <li>{this.props.english ? english.para_cellfree_lysate13 : french.para_cellfree_lysate13}</li>
            </ol>
            <SubtitleContainer text={this.props.english ? english.subtitle_cellfree_wizard : french.subtitle_cellfree_wizard} />
            <TextContainer text={this.props.english ? english.para_cellfree_wizard1 : french.para_cellfree_wizard1} />
            <div className="cellfree-table protocol-table">
                <ImageContainer image={CellFree_Table1} />
            </div>
            <TextContainer text={this.props.english ? english.para_cellfree_wizard2 : french.para_cellfree_wizard2} />
            <SubtitleContainer text={this.props.english ? english.subtitle_cellfree_reaction : french.subtitle_cellfree_reaction} />
            <TextContainer text={this.props.english ? english.para_cellfree_reaction1 : french.para_cellfree_reaction1} />
            <div className="cellfree-table protocol-table">
                <ImageContainer image={CellFree_Table2} />
            </div>
            <TextContainer text={this.props.english ? english.para_cellfree_reaction2 : french.para_cellfree_reaction2} />
            <TextContainer text={this.props.english ? english.para_cellfree_reaction3 : french.para_cellfree_reaction3} />
            </>
        );
    }
}

export default CellFreeBody;