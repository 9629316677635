import React from "react";
import "./AutoinductionBody.css";
import SubtitleContainer from "../primitive/SubtitleContainer";
import TextImageContainer from "../primitive/TextImageContainer";
import TextContainer from "../primitive/TextContainer";
import CrudeFigure4 from "../../images/CrudeFigure4.png";
import CrudeFigure5 from "../../images/CrudeFigure5.png";
import english from "../../text_files/english.json";
import french from "../../text_files/french.json";
import parse from "html-react-parser";

class CrudeExtractProof extends React.Component {
    render() {
        return(
            <>
            <SubtitleContainer text={this.props.english ? english.subtitle_crude_proof : french.subtitle_crude_proof} />
            <TextContainer text={this.props.english ? parse(english.para_crude_proof) : parse(french.para_crude_proof)} />
            <div className="protocol-table">
                <TextImageContainer textLeft={false} text={this.props.english ? parse(english.para_crude_figure4) : parse(french.para_crude_figure4)} image={CrudeFigure4} />
            </div>
            <div className="protocol-table">
                <TextImageContainer textLeft={false} text={this.props.english ? parse(english.para_crude_figure5) : parse(french.para_crude_figure5)} image={CrudeFigure5} />
            </div>
            </>
        );
    }
}

export default CrudeExtractProof;