import React from "react";
import "../components/AboutWrapper.css";
import SubtitleContainer from "./primitive/SubtitleContainer";
import TextContainer from "./primitive/TextContainer";
import { Image } from 'react-bootstrap'; 

class AboutWrapper extends React.Component {
    render() { 
        return (
        <>
        <div className="rectangle-wrapper">
            <Image className="image-about" src={this.props.image} />
            <div className="big-subtitle-wrapper">
                <SubtitleContainer text={this.props.subtitle} />
            </div>
            <div className="text-wrapper-rectangle">
                <div className="subtitle-rectangle">
                    <SubtitleContainer text={this.props.subtitle} />
                </div> 
                <div className="text-rectangle">
                    <TextContainer text={this.props.description} />
                </div>
            </div>
        </div>
        </>
        );
    }
}

export default AboutWrapper;